import React from 'react'
import RowExpandedProperty from '../../../components/RowExpandedProperty'
import { Check } from '@mui/icons-material'

const WorkflowExpandedRow = ({ data: workflow }) => {
	const { name, type, borrowerType, description, isDefault } =
		workflow
	return (
		<div className="p-5">
			<RowExpandedProperty label="Name" value={name} />
			<RowExpandedProperty label="Type" value={type} />
			<RowExpandedProperty
				label="Borrower Type"
				value={borrowerType}
			/>
			<RowExpandedProperty
				label="Description"
				value={description ? description : '-'}
			/>
			<RowExpandedProperty
				label="Default"
				value={isDefault ? <Check /> : '-'}
			/>
		</div>
	)
}

export default WorkflowExpandedRow
