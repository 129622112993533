import React from 'react'
import useFormField from '../../hooks/useFormField'
import Dropzone from '../Dropzone'
import { Controller } from 'react-hook-form'

const DropzoneField = ({ name, control, errors, ...rest }) => {
	const { getError } = useFormField()
	const error = getError(name, errors)

	if (!control) {
		return <Dropzone name={name} errors={errors} {...rest} />
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<Dropzone
					{...field}
					error={!!error}
					helperText={error ? error.message : undefined}
					onDropAccepted={field.onChange}
					{...rest}
				/>
			)}
		/>
	)
}

export default DropzoneField
