import React, { useEffect, useState } from 'react'
import { getTheme } from '../../config'
import {
	FormGroup,
	FormControlLabel,
	Switch,
	TextField,
} from '@mui/material'
import { SiteTypes } from '../../services/client'

const theme = getTheme()

const sortArray = (arr1, arr2) => {
	arr2.sort((a, b) => {
		const aKey = a.title
		const bKey = b.title
		return (
			arr1.findIndex((f) => f.field === aKey) -
			arr1.findIndex((f) => f.field === bKey)
		)
	})
}

const currentFeatures = [
	{
		field: 'leads',
		label: 'Leads',
		description: 'Show Leads Card on Dashboard',
		hideForSiteTypes: [],
	},
	{
		field: 'leadApp',
		label: 'Lead App (Super Short App)',
		description:
			'The shortest Loan Application with minimal information',
		hideForSiteTypes: [],
	},
	{
		field: 'mobilePrequal',
		label: 'Prequal (Short App)',
		description:
			'Loan application with enough information to make a Pre-Qualification decision',
		hideForSiteTypes: [],
	},
	{
		field: 'fullApp',
		label: 'Full App (Full URLA)',
		description:
			'The "Soup to Nuts" application with almost all of the URLA fields',
		hideForSiteTypes: [],
	},
	{
		field: 'spanishPrequal',
		label: 'Spanish Prequal (Short App)',
		description: 'Spanish version of the Prequal (Short App)',
		hideForSiteTypes: [],
	},
	{
		field: 'spanishFullApp',
		label: 'Spanish Full App (Full URLA)',
		description: 'Spanish version of the Full App (Full URLA)',
		hideForSiteTypes: [],
	},
	{
		field: 'buyerClient',
		label: 'Buyer Lead Form',
		description: 'Lead form for Buyer Clients',
		hideForSiteTypes: [
			SiteTypes.corporate,
			SiteTypes.branch,
			SiteTypes.loanOfficer,
		],
	},
	{
		field: 'sellerClient',
		label: 'Seller Lead Form',
		description: 'Lead form for Seller clients',
		hideForSiteTypes: [
			SiteTypes.corporate,
			SiteTypes.branch,
			SiteTypes.loanOfficer,
		],
	},
	{
		field: 'clientLifestyle',
		label: 'Client Lifestyle',
		description: 'Gather valuable information about your clients',
		hideForSiteTypes: [
			SiteTypes.corporate,
			SiteTypes.branch,
			SiteTypes.loanOfficer,
		],
	},
	{
		field: 'openHouseForm',
		label: 'Open House Form',
		description: 'Show open house check-in form',
		hideForSiteTypes: [
			SiteTypes.corporate,
			SiteTypes.branch,
			SiteTypes.loanOfficer,
		],
	},
	{
		field: 'listingOfferForm',
		label: 'Listing Offer Form',
		description: 'Show listing offer form',
		hideForSiteTypes: [
			SiteTypes.corporate,
			SiteTypes.branch,
			SiteTypes.loanOfficer,
		],
	},
	// { field: "alexa", label: "Alexa", description: "Enabled Alexa Loan Application", hideForSiteTypes: [] },
	{
		field: 'ringCentral',
		label: 'Apply By Phone',
		description:
			'Display an icon for one-touch dialing to take applications by phone',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'pricingCalculator',
		label: 'Pricing Calculator',
		description:
			'Allow Loan Officers to Calculation Pricing.\n* Requires Pricing integration',
		hideForSiteTypes: [],
	},
	// { field: "rates", label: "Rates", description: "Display Rates in real-time. * Requires Pricing integration", hideForSiteTypes: [SiteTypes.realtor] },
	{
		field: 'borrowerTasks',
		label: 'Borrower Tasks',
		description: 'Display a list of Tasks for the user to complete',
		hideForSiteTypes: [],
	},
	{
		field: 'documents',
		label: 'Documents',
		description: 'Show Document Vault',
		hideForSiteTypes: [],
	},
	{
		field: 'loanCalculator',
		label: 'Loan Calculator',
		description:
			'Allow Loan Structuring for Loan Officers and Partners',
		hideForSiteTypes: [],
	},
	{
		field: 'emailNotifications',
		label: 'Email Notifications',
		description:
			'Notify users when an action is performed or the changes are made',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	// { field: "borrowerMissions", label: "Borrower Missions", description: "Enables Borrower Missions", hideForSiteTypes: [SiteTypes.realtor] },
	{
		field: 'docusign',
		label: 'Docusign',
		description:
			'Enables E-Signature tasks\n* Requires Docusign integration',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'disclosures',
		label: 'Disclosures',
		description:
			'Enables Single Sign On (SSO) to Consumer Connect\n* Requires Consumer Connect connection',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'payments',
		label: 'Payments',
		description:
			'Show a button to allow Borrowers to make first-time payments or pay a bill\n* Requires Finigree integration',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'voc',
		label: 'VOC (Soft Pull)',
		description: 'Enabled Verification of Credit (VOC)',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'socialSurvey',
		label: 'Experience.com Reviews',
		description:
			'Show reviews from Experience.com on landing pages\n* Requires Experience.com integration',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'encompassWeb',
		label: 'Encompass Web',
		description: 'Enable Encompass Web Tile in Loan Officer Portal',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'support',
		label: 'Support',
		description: 'Enable Support Tile in Loan Officer Portal',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'loanDetails',
		label: 'Loan Details',
		description: 'Show loan details to borrowers.',
		hideForSiteTypes: [],
	},
	{
		field: 'listings',
		label: 'Listings',
		description: 'Show listings on the Dashboard',
		hideForSiteTypes: [
			SiteTypes.admin,
			SiteTypes.corporate,
			SiteTypes.branch,
		],
	},
	// { field: "textNotifications", label: "Text Notifications", description: "Enabled Text Notifications", hideForSiteTypes: [SiteTypes.realtor] }
]

export const Features = ({ formData, setFormData }) => {
	const [systemFeatures, setSystemFeatures] = useState([])
	const [servicesId, setServicesId] = useState('')

	useEffect(() => {
		setServicesId(formData?.enabledServices.id)
		const servicesList = Object.entries(formData?.enabledServices)
			.reduce(
				(acc, val) => [...acc, { title: val[0], checked: val[1] }],
				[]
			)
			.filter(
				(x) =>
					x.title !== 'id' &&
					x.title !== 'createdAt' &&
					x.title !== 'updatedAt' &&
					x.title !== 'deletedAt'
			)
		sortArray(currentFeatures, servicesList)
		setSystemFeatures(servicesList)
	}, [formData])

	const handleSelect = (title) => {
		const checkedItem = systemFeatures.findIndex(
			(x) => x.title === title
		)
		const checked = { ...systemFeatures[checkedItem] }
		systemFeatures[checkedItem] = checked
		checked.checked = !checked.checked
		const enabledServices = systemFeatures.reduce(
			(acc, val) => ({ ...acc, [val.title]: val.checked }),
			{}
		)
		enabledServices.id = servicesId
		setFormData({
			...formData,
			enabledServices,
		})
	}

	const handleInputChangeText = (field, value) => {
		setFormData({
			...formData,
			[field]: value,
		})
	}

	return (
		<div className="flex flex-col">
			<p className="mt-5 mb-2.5 text-sm font-rubik">
				Global System Features
			</p>
			<FormGroup>
				{systemFeatures.map((item, index) => {
					const feature = currentFeatures.find(
						(c) => c.field === item.title
					)
					if (
						feature &&
						!feature.hideForSiteTypes.includes(formData.entityType)
					)
						return (
							<div
								className="pt-2 pb-2 flex flex-col sm:flex-row border-b last:border-0"
								key={feature.field}
							>
								<div className="w-full sm:w-1/2">
									<div
										className="pt-1.5 text-lg font-rubik text-button-blue w-full font-bold"
										style={{ color: theme.siteConfig.color.primary }}
									>
										{feature.label}
									</div>
									<div className="text-sm font-rubik">
										{feature.description}
									</div>
								</div>
								<div className="flex flex-col w-full sm:w-1/2 pt-2 sm:pt-0 sm:pl-5 self-center">
									<FormControlLabel
										label={`${feature.label} ${feature.label.slice(-1) !== 's' ? 'is' : 'are'}  ${item.checked ? 'on' : 'off'}`}
										key={`${item + index}`}
										control={
											<Switch
												id={`Features${item.title}Checkbox`}
												checked={item.checked}
												onChange={() => handleSelect(item.title)}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
									/>

									{item.title === 'loanDetails' && item.checked && (
										<div className="w-1/2">
											<FormControlLabel
												label={`Loan Status Tab is ${systemFeatures.find((s) => s.title === 'loanStatus').checked ? 'on' : 'off'}`}
												key={`${item + index}`}
												control={
													<Switch
														id={`FeaturesLoanStatusCheckbox`}
														checked={
															systemFeatures.find(
																(s) => s.title === 'loanStatus'
															).checked
														}
														onChange={() =>
															handleSelect('loanStatus')
														}
														inputProps={{
															'aria-label': 'controlled',
														}}
													/>
												}
											/>
											<FormControlLabel
												label={`Loan And Property Tab is ${systemFeatures.find((s) => s.title === 'loanAndProperty').checked ? 'on' : 'off'}`}
												key={`${item + index}`}
												control={
													<Switch
														id={`FeaturesLoanAndPropertyCheckbox`}
														checked={
															systemFeatures.find(
																(s) => s.title === 'loanAndProperty'
															).checked
														}
														onChange={() =>
															handleSelect('loanAndProperty')
														}
														inputProps={{
															'aria-label':
																'Loan and Property Information',
														}}
													/>
												}
											/>
											<FormControlLabel
												label={`Personal Information Tab is ${systemFeatures.find((s) => s.title === 'personalInformation').checked ? 'on' : 'off'}`}
												key={`${item + index}`}
												control={
													<Switch
														id={`FeaturesPersonalInfoTabCheckbox`}
														checked={
															systemFeatures.find(
																(s) =>
																	s.title === 'personalInformation'
															).checked
														}
														onChange={() =>
															handleSelect('personalInformation')
														}
														inputProps={{
															'aria-label': 'Personal Information',
														}}
													/>
												}
											/>
											<FormControlLabel
												label={`Employment Information Tab is ${systemFeatures.find((s) => s.title === 'employment').checked ? 'on' : 'off'}`}
												key={`${item + index}`}
												control={
													<Switch
														id={`FeaturesEmploymentInfoTabCheckbox`}
														checked={
															systemFeatures.find(
																(s) => s.title === 'employment'
															).checked
														}
														onChange={() =>
															handleSelect('employment')
														}
														inputProps={{
															'aria-label': 'Employment Information',
														}}
													/>
												}
											/>
											<FormControlLabel
												label={`Assets Tab is ${systemFeatures.find((s) => s.title === 'assets').checked ? 'on' : 'off'}`}
												key={`${item + index}`}
												control={
													<Switch
														id={`FeaturesAssetsTabCheckbox`}
														checked={
															systemFeatures.find(
																(s) => s.title === 'assets'
															).checked
														}
														onChange={() => handleSelect('assets')}
														inputProps={{ 'aria-label': 'Assets' }}
													/>
												}
											/>
											<FormControlLabel
												label={`Real Estate Tab is ${systemFeatures.find((s) => s.title === 'realEstate').checked ? 'on' : 'off'}`}
												key={`${item + index}`}
												control={
													<Switch
														id={`FeaturesRealEstateTabCheckbox`}
														checked={
															systemFeatures.find(
																(s) => s.title === 'realEstate'
															).checked
														}
														onChange={() =>
															handleSelect('realEstate')
														}
														inputProps={{
															'aria-label': 'Real Estate',
														}}
													/>
												}
											/>
										</div>
									)}

									{item.title === 'ringCentral' && item.checked && (
										<div className="flex flex-col w-1/2">
											<TextField
												onChange={(e) =>
													handleInputChangeText(
														'byPhoneTitle',
														e.target.value
													)
												}
												value={formData?.byPhoneTitle}
												label="Title Override"
												placeholder="Title Override"
												variant="standard"
												className="mr-5"
											/>
											<TextField
												onChange={(e) =>
													handleInputChangeText(
														'byPhoneSubtitle',
														e.target.value
													)
												}
												value={formData?.byPhoneSubtitle}
												label="Description Override"
												placeholder="Description Override"
												variant="standard"
											/>
										</div>
									)}

									{item.title === 'mobilePrequal' && item.checked && (
										<div className="flex flex-col w-1/2">
											<TextField
												onChange={(e) =>
													handleInputChangeText(
														'shortAppTitle',
														e.target.value
													)
												}
												value={formData?.shortAppTitle}
												label="Title Override"
												placeholder="Title Override"
												variant="standard"
											/>
											<TextField
												onChange={(e) =>
													handleInputChangeText(
														'shortAppSubtitle',
														e.target.value
													)
												}
												value={formData?.shortAppSubtitle}
												label="Description Override"
												placeholder="Description Override"
												variant="standard"
											/>
										</div>
									)}

									{item.title === 'fullApp' && item.checked && (
										<div className="flex flex-col w-1/2">
											<TextField
												onChange={(e) =>
													handleInputChangeText(
														'fullAppTitle',
														e.target.value
													)
												}
												value={formData?.fullAppTitle}
												label="Title Override"
												placeholder="Title Override"
												variant="standard"
												className="mr-5"
											/>
											<TextField
												onChange={(e) =>
													handleInputChangeText(
														'fullAppSubtitle',
														e.target.value
													)
												}
												value={formData?.fullAppSubtitle}
												label="Description Override"
												placeholder="Description Override"
												variant="standard"
											/>
										</div>
									)}

									{item.title === 'spanishPrequal' &&
										item.checked && (
											<div className="flex flex-col w-1/2">
												<TextField
													onChange={(e) =>
														handleInputChangeText(
															'spanishPrequalTitle',
															e.target.value
														)
													}
													value={formData?.spanishPrequalTitle}
													label="Title Override"
													placeholder="Title Override"
													variant="standard"
													className="mr-5"
												/>
												<TextField
													onChange={(e) =>
														handleInputChangeText(
															'spanishPrequalSubtitle',
															e.target.value
														)
													}
													value={formData?.spanishPrequalSubtitle}
													label="Description Override"
													placeholder="Description Override"
													variant="standard"
												/>
											</div>
										)}

									{item.title === 'spanishFullApp' &&
										item.checked && (
											<div className="flex flex-col w-1/2">
												<TextField
													onChange={(e) =>
														handleInputChangeText(
															'spanishFullAppTitle',
															e.target.value
														)
													}
													value={formData?.spanishFullAppTitle}
													label="Title Override"
													placeholder="Title Override"
													variant="standard"
													className="mr-5"
												/>
												<TextField
													onChange={(e) =>
														handleInputChangeText(
															'spanishFullAppSubtitle',
															e.target.value
														)
													}
													value={formData?.spanishFullAppSubtitle}
													label="Description Override"
													placeholder="Description Override"
													variant="standard"
												/>
											</div>
										)}

									{item.title === 'emailNotifications' &&
										item.checked && (
											<div className="flex flex-col w-1/2">
												<TextField
													onChange={(e) =>
														handleInputChangeText(
															'ccEmails',
															e.target.value
														)
													}
													value={formData?.ccEmails}
													label="CC Emails"
													placeholder="CC Emails"
													variant="standard"
													helperText="enter emails separated by a semicolon (;)"
												/>
											</div>
										)}
								</div>
							</div>
						)
				})}
			</FormGroup>
		</div>
	)
}
