import React, { useState, useEffect, useMemo } from 'react'
import { useVenti } from 'venti'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import Page from '../../../components/Page'
import { useAppContext } from '../../../components/AppContext'
import { useAppContextActions } from '../../../components/AppContext/AppHooks'
import { getTheme } from '../../../config'
import {
	createUser,
	deleteUser,
	undeleteUser,
	Roles,
	requestImpersonation,
	searchUsers,
	forceImpersonation,
	nukeUser,
} from '../../../services/client'
import {
	formatDate,
	getErrorMessage,
	getMixpanelUserProperties,
} from '../../../services/helper'
import userQuestions from '../../../workflows/user-questions.js'
import { useAlert } from '../../../hooks'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material'
import { ModalUser } from '../../../components/modals/ModalUser'
import { ModalChangePassword } from '../../../components/modals/ModalChangePassword'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants.js'
import queryKeys from '../../../services/queryKeys.js'
import PaginatedDataTable from '../../../components/PaginatedDataTable.js'
import useSearchText from '../../../hooks/useSearchText.js'
import { navigationLinking } from '../../../services/navigation'
import { useNavigate } from 'react-router-dom'
import { Warning } from '@mui/icons-material'

const theme = getTheme()

export default function AdminUsersView() {
	const { defaultPageSize, defaultSortColumn, defaultSortDirection } =
		theme.pagination
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { applyImpersonationRequest, applyUser } =
		useAppContextActions()
	const navigate = useNavigate()
	const { user, siteConfig } = state
	const ventiState = useVenti()
	const queryClient = useQueryClient()
	const [modalChangePassword, setModalChangePassword] = useState({
		visible: false,
		data: null,
	})
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] =
		useState(false)
	const [activeRow, setActiveRow] = useState()
	const mixpanel = useMixpanel()
	const [filters, setFilters] = useState({
		role: 'all',
		active: 'active',
	})
	const [modalAddNewUser, setModalAddNewUser] = useState({
		visible: false,
		data: {},
		title: 'Create Admin User',
	})
	const [paginationParams, setPaginationParams] = useState({
		pageSize: defaultPageSize,
		pageNumber: 1,
		sortBy: defaultSortColumn,
		sortDirection: defaultSortDirection,
	})

	const {
		isFetching,
		isRefetching,
		isError,
		data: usersResult,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.users],
		queryFn: () => {
			const filterPayload = {
				searchText,
				isActive:
					filters?.active === 'all'
						? null
						: filters?.active === 'active',
				roles: filters?.role === 'all' ? null : [filters?.role],
			}
			return searchUsers(filterPayload, paginationParams)
		},
		retry: 2,
	})
	const {
		searchText,
		onChange: onSearchChange,
		onClear: onSearchClear,
	} = useSearchText({ onFetch: refetch })

	const handlePaginationChange = (params) => {
		setPaginationParams(params)
	}
	const totalCount = useMemo(() => {
		return usersResult ? usersResult?.count : 0
	}, [usersResult])

	const handleFilterChange = (e, field) => {
		setFilters((f) => ({ ...f, [field]: e.target.value }))
	}
	const columns = [
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'First Name',
			selector: (row) => row.firstName,
			sortable: true,
		},
		{
			name: 'Last Name',
			selector: (row) => row.lastName,
			sortable: true,
		},
		{
			name: 'Role',
			selector: (row) => row.role,
			sortable: true,
		},
		{
			name: 'Created',
			selector: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
	]

	useEffect(() => {
		refetch()
	}, [paginationParams, filters, refetch])

	useEffect(() => {
		if (isError) {
			alert('There was a problem loading the users', {
				severity: 'error',
			})
		}
	}, [isError, alert])

	const handleSaveUser = async (data) => {
		const result = await createUserMutation.mutateAsync({
			...data,
			siteConfigurationId: siteConfig.id,
			userRole: Roles.admin,
			phone: `+1${data.phone.replace(/\D/g, '')}`,
		})
		mixpanel.trackEvent(
			eventTypes.USER_CREATED,
			getMixpanelUserProperties(result)
		)
		return result
	}

	const showRemoveModal = (row) => {
		setActiveRow({ ...row, name: `${row.firstName} ${row.lastName}` })
		setRemoveModalVisible(true)
	}

	const showChangePWModal = (data) => {
		setModalChangePassword({
			visible: true,
			data,
		})
	}

	const createUserMutation = useMutation({
		mutationFn: (data) => createUser(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries([queryKeys.users])
		},
	})

	const removeMutation = useMutation({
		mutationFn: (id) => deleteUser(id),
		onSuccess: async () => {
			await queryClient.invalidateQueries([queryKeys.users])

			mixpanel.trackEvent(
				eventTypes.USER_DELETED,
				getMixpanelUserProperties(activeRow)
			)
			setRemoveModalVisible(false)
			alert(
				`User "${activeRow.firstName} ${activeRow.lastName}" successfully removed`
			)
			setActiveRow(null)
		},
		onError: (e) => {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		},
	})

	const restoreMutation = useMutation({
		mutationFn: (id) => undeleteUser(id),
		onSuccess: async () => {
			await queryClient.invalidateQueries([queryKeys.users])

			mixpanel.trackEvent(
				eventTypes.USER_RESTORED,
				getMixpanelUserProperties(activeRow)
			)

			setRestoreModalVisible(false)
			alert(
				`User "${activeRow.firstName} ${activeRow.lastName}" successfully restored`
			)
			setActiveRow(null)
		},
		onError: (e) => {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		},
	})

	const nukeMutation = useMutation({
		mutationFn: (id) => nukeUser(id),
		onSuccess: async () => {
			await queryClient.invalidateQueries([queryKeys.users])

			mixpanel.trackEvent(
				eventTypes.USER_NUKED,
				getMixpanelUserProperties(activeRow)
			)
			setRemoveModalVisible(false)
			alert(
				`User "${activeRow.firstName} ${activeRow.lastName} (${activeRow.email})" was permanently deleted`
			)
			setActiveRow(null)
		},
		onError: (e) => {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		},
	})

	const restoreUser = async () => {
		if (activeRow && activeRow?.id)
			await restoreMutation.mutateAsync(activeRow?.id)
	}

	const removeUser = async () => {
		if (activeRow && activeRow?.id)
			if (!activeRow?.nuke)
				await removeMutation.mutateAsync(activeRow.id)
			else await nukeMutation.mutateAsync(activeRow.id)
	}

	const showRestoreModal = (row) => {
		setRestoreModalVisible(true)
		setActiveRow({ ...row, name: `${row.firstName} ${row.lastName}` })
	}

	const onRowClicked = () => {
		// todo: user edit with proper functionality
	}

	const handleForceImpersonation = async (row) => {
		try {
			await forceImpersonation(row.email)

			mixpanel.trackEvent(eventTypes.IMPERSONATION_FORCED, {
				impersonator: getMixpanelUserProperties(user),
				impersonatee: getMixpanelUserProperties(row),
			})

			applyImpersonationRequest({
				status: 'Active',
				impersonator: {
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
				},
				impersonatee: {
					firstName: row.firstName,
					lastName: row.lastName,
					email: row.email,
				},
			})

			applyUser({
				...user,
				role: row.role || 'Borrower',
			})

			navigate(`/${navigationLinking.Portal}`)
		} catch (e) {}
	}

	const handleImpersonationRequest = async (row) => {
		try {
			await requestImpersonation(row.email)
			alert(
				`Impersonation request was sent to ${row.firstName} ${row.lastName} (${row.email})`
			)

			mixpanel.trackEvent(eventTypes.IMPERSONATION_REQUESTED, {
				impersonator: getMixpanelUserProperties(user),
				impersonatee: getMixpanelUserProperties(row),
			})

			applyImpersonationRequest({
				status: 'Requested',
				impersonator: {
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
				},
				impersonatee: {
					firstName: row.firstName,
					lastName: row.lastName,
					email: row.email,
				},
			})
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const handleAddNewUser = () => {
		setModalAddNewUser((current) => ({ ...current, visible: true }))
	}

	const actionItems = [
		{
			name: 'Change Password',
			onClick: (e, row) => showChangePWModal(row),
			hideIf: (row) => row.deletedAt !== null,
		},
		{
			name: 'Delete',
			onClick: (e, row) => showRemoveModal(row),
			hideIf: (row) => row.deletedAt !== null,
		},
		{
			name: 'Restore',
			onClick: (e, row) => showRestoreModal(row),
			hideIf: (row) => row.deletedAt === null,
		},
	]

	if (user.canImpersonate || user.role === Roles.superAdmin)
		actionItems.push({
			name: 'Impersonate',
			onClick: (e, row) =>
				user.role === Roles.superAdmin
					? handleForceImpersonation(row)
					: handleImpersonationRequest(row),
			hideIf: (row) => row.role === 'Admin',
		})

	if (user.role === Roles.superAdmin)
		actionItems.push({
			name: (
				<p>
					Nuke User <Warning />
				</p>
			),
			onClick: (e, row) => showRemoveModal({ ...row, nuke: true }),
			hideIf: null,
		})

	return (
		<Page title="Users" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ModalChangePassword
					modal={modalChangePassword.visible}
					setModal={setModalChangePassword}
					modalData={modalChangePassword.data}
					modalTitle={`Change Password`}
					user={user}
				/>
				<ModalUser
					modal={modalAddNewUser}
					setModal={setModalAddNewUser}
					questions={userQuestions}
					save={handleSaveUser}
				/>
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeUser}
					row={activeRow}
					loading={removeMutation.isPending}
				/>
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restoreUser}
					row={activeRow}
					loading={restoreMutation.isPending}
				/>
				<PaginatedDataTable
					data={usersResult?.rows}
					columns={columns}
					defaultSortAsc={defaultSortDirection === 'asc'}
					defaultSortFieldId={defaultSortColumn}
					paginationTotalRows={totalCount}
					onPaginationChange={handlePaginationChange}
					progressPending={isFetching && !isRefetching}
					title="Users"
					onRefreshClick={refetch}
					keyField="id"
					onSearchChange={onSearchChange}
					onClearSearchClick={onSearchClear}
					searchText={searchText}
					// expandableRowsComponent={}
					refreshing={isRefetching}
					searchFilters={
						<>
							<FormControl id="filterType" variant="standard">
								<InputLabel>Role</InputLabel>
								<Select
									value={filters.role}
									onChange={(e) => handleFilterChange(e, 'role')}
								>
									<MenuItem value="all">All</MenuItem>
									{Object.values(Roles)
										.sort()
										.map((role) => (
											<MenuItem key={role} value={role}>
												{role}
											</MenuItem>
										))}
								</Select>
							</FormControl>
							<FormControl id="filterActive" variant="standard">
								<InputLabel>Active / Deleted</InputLabel>
								<Select
									value={filters.active}
									onChange={(e) => handleFilterChange(e, 'active')}
								>
									<MenuItem value="active">Show Active Only</MenuItem>
									<MenuItem value="deleted">
										Show Deleted Only
									</MenuItem>
									<MenuItem value="all">Show All</MenuItem>
								</Select>
							</FormControl>
						</>
					}
					exportEnabled={true}
					fixedHeader={true}
					actionItems={actionItems}
					addNewBtn={{
						text: 'Add Admin User',
						onClick: handleAddNewUser,
					}}
				/>
			</div>
		</Page>
	)
}
