import { Button as ButtonMUI } from '@mui/material'

export const Button = ({
	text,
	id = '',
	onClick,
	disabled,
	variant = 'contained',
	style = {},
	...rest
}) => (
	<ButtonMUI
		id={id}
		disabled={disabled}
		onClick={onClick}
		variant={variant}
		style={{
			textTransform: 'none',
			fontSize: '14px',
			paddingBottom: '4px',
			...style,
		}}
		{...rest}
	>
		{text}
	</ButtonMUI>
)
