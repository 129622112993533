import React, { useEffect, useState } from 'react'
import { useVenti } from 'venti'
import PublishSubscribe from 'publish-subscribe-js'
import Page from '../../../components/Page'
import { DataTable } from '../../../components/DataTable'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import { useAppContext } from '../../../components/AppContext'
import { useAppContextActions } from '../../../components/AppContext/AppHooks'
import {
	getCorporations,
	deleteCorporate,
	undeleteCorporate,
} from '../../../services/client'
import { getErrorMessage } from '../../../services/helper'
import { getTheme } from '../../../config'
import { useAlert } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'

const theme = getTheme()

export default function AdminCorporations() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state
	const ventiState = useVenti()
	const mixpanel = useMixpanel()

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] =
		useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [rowToRestore, setRowToRestore] = useState()

	const headers = [
		{ id: 'name', label: 'Name' },
		{ id: 'nmlsid', label: 'NMLSID' },
		{ id: 'email', label: 'Email' },
		{ id: 'domain', label: 'URL' },
		{ id: 'created', label: 'Created On' },
	]

	const fields = ['name', 'nmlsid', 'email', 'url', 'createdAt']

	useEffect(() => {
		;(async () => {
			if (!user.isLoggedIn) await logOut()
			else await loadCorporations()
		})()
	}, [user])

	useEffect(() => {
		PublishSubscribe.subscribe(
			'UPDATE_ADMIN_COMPONENT_STATE',
			async () => {
				await updateCorporations()
			}
		)

		return () => {
			PublishSubscribe.unsubscribe('UPDATE_ADMIN_COMPONENT_STATE')
		}
	}, [])

	const loadCorporations = async () => {
		try {
			setFetching(true)
			const corporationsData = await getCorporations(true)
			const corporations = []
			corporationsData.forEach((item) => {
				item?.siteConfigurations.forEach((site) => {
					// id is being removed, so replaced with ID
					// will figure out later why
					corporations.push({
						...site,
						ID: site.id,
						corporateId: item.id,
						createdAt: item.createdAt,
						deletedAt: item.deletedAt,
					})
				})
			})
			setData(corporations)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setRowToRemove(row)
	}

	const updateCorporations = async () => {
		const result = await getCorporations()
		setData(result)
	}

	const removeCorporate = async () => {
		setRemoveModalVisible(false)

		try {
			const { id, name } = rowToRemove
			await deleteCorporate(rowToRemove.corporateId)
			await loadCorporations()
			mixpanel.trackEvent(eventTypes.BRAND_DELETED, {
				name,
				id,
			})
			alert(`Brand "${rowToRemove.name}" successfully removed`)
			setRowToRemove(null)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const showRestoreModal = (row) => {
		setRestoreModalVisible(true)
		setRowToRestore(row)
	}

	const restoreCorporate = async () => {
		setRestoreModalVisible(false)

		try {
			await undeleteCorporate(rowToRestore.corporateId)
			setData((data) => data.filter((d) => d.id !== rowToRestore.id))
			alert(`Brand ${rowToRestore.name} successfully restored`)
			setRowToRestore(null)
			await loadCorporations()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	return (
		<Page title="Brands" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeCorporate}
					row={rowToRemove}
				/>
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restoreCorporate}
					row={rowToRestore}
				/>
				<DataTable
					data={data}
					fields={fields}
					headers={headers}
					entity={'Corporate'}
					title={'Brands'}
					buttonTitle={'Add Brand'}
					fetching={fetching}
					refresh={loadCorporations}
					remove={showRemoveModal}
					restore={showRestoreModal}
					allowExport
				/>
			</div>
		</Page>
	)
}
