import * as yup from 'yup'

const yupOptionalNumberTransform = (value) =>
	Number.isNaN(value) ? null : value

/**
 * @deprecated use yup.mixed().optionalIntegerRange method instead
 */
const yupOptionalIntegerRange = (min, max) => {
	return yup
		.number()
		.transform(yupOptionalNumberTransform)
		.nullable()
		.optional()
		.integer()
		.min(min)
		.max(max)
}

const addCustomYupValidators = () => {
	yup.addMethod(yup.mixed, 'nullableNumber', function () {
		return yup
			.number()
			.transform((v, o) => (o === '' ? null : v))
			.nullable()
	})

	yup.addMethod(
		yup.mixed,
		'optionalIntegerRange',
		function (min, max) {
			return yup
				.number()
				.transform(yupOptionalNumberTransform)
				.nullable()
				.optional()
				.integer()
				.min(min)
				.max(max)
		}
	)
}

export { addCustomYupValidators, yupOptionalIntegerRange }
