import React, { useEffect, useMemo } from 'react'
import Form from '../components/formControls/Form'
import * as yup from 'yup'
import useForm from '../hooks/useForm'
import Select from '../components/formControls/Select'
import {
	Checkbox,
	FormRow,
	TextField,
} from '../components/formControls'
import { LoadingBtn } from '../components/Button'
import { Button, FormHelperText } from '@mui/material'
import useUser from '../hooks/useUser'
import { Roles } from '../services/client'
import TaskLosTargetField from '../components/formControls/TaskLosTargetField'
import NumberField from '../components/formControls/NumberField'

const TaskForm = ({
	task,
	onSubmit,
	onCancel = undefined,
	apiErrors = [],
	loanId = undefined,
	borrowers = [],
}) => {
	const { user } = useUser()

	const borrowerSelectEnabled = loanId && borrowers.length > 0

	const schema = yup.object().shape({
		name: yup.string().required('Name is required'),
		type: yup.string().required('Type is required'),
		description: yup.string().required('Description is required'),
		daysDueFromApplication: yup
			.number()
			.required('Task Duration is required')
			.transform((val, orig) => (orig === '' ? undefined : val)),
		targetUserRole: borrowerSelectEnabled
			? yup.string().nullable()
			: yup.string().required('User role is required'),
		borrowers: yup.array().when('targetUserRole', {
			is: null,
			then: yup
				.array()
				.min(1)
				.required('At least borrower is required'),
		}),
	})

	const values = task || {
		name: task?.name || '',
		description: task?.description || '',
		type: task?.type || '',
		daysDueFromApplication: task?.daysDueFromApplication || '',
		targetUserRole: task?.targetUserRole || '',
		isGlobal: task?.isGlobal || false,
		losTarget: task?.losTarget || '',
	}

	values.borrowers = []

	const form = useForm({
		schema,
		values,
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
		watch,
		setValue,
	} = form

	const typeOptions = [
		{ value: 'Document', label: 'Provide a Document' },
		{ value: 'Field', label: 'Request Information' },
		{
			value: 'VerificationOfAssets',
			label: 'Verify Assets (VOA)',
		},
		{
			value: 'VerificationOfIncome',
			label: 'Verify Income (VOI)',
		},
		{
			value: 'VerificationOfEmployment',
			label: 'Verify Employment (VOE)',
		},
		{
			value: 'VerificationOfIncomeAndEmployment',
			label: 'Verify Income & Employment (VOIE)',
		},
	]

	const userRoleOptions = [
		{
			value: 'Borrowers',
			label: 'All Borrowers',
		},
		{
			value: 'Borrower',
			label: 'Borrower',
		},
		{
			value: 'CoBorrower',
			label: 'Co-Borrower',
		},
	]

	const type = watch('type')

	useEffect(() => {
		const option = typeOptions.find((o) => o.value === type)
		const target = task?.losTarget || option?.losTarget
		setValue('losTarget', target)
	}, [type])

	const borrowerOptions = useMemo(() => {
		return borrowers.map((borrower) => ({
			value: borrower.id,
			label: `${borrower.firstName} ${borrower.lastName}`,
			role: borrower.role,
		}))
	}, [borrowers])

	const getBorrowerOptionByRole = (role) => {
		return borrowerOptions.find((b) => b.role === role)
	}

	useEffect(() => {
		if (borrowerOptions.length === 0) return

		// pre-populate the borrowers field
		if (borrowerOptions.length === 1) {
			setValue('borrowers', [borrowerOptions[0].value]) // default to only borrower
		} else {
			if (task?.targetUserRole) {
				// add borrowers based on targetUserRole
				const borrowerOptionsFromTargetRole = []
				switch (task?.targetUserRole) {
					case 'Borrowers':
						borrowerOptions.forEach((borrower) => {
							borrowerOptionsFromTargetRole.push(borrower.value)
						})
						break
					case 'Borrower':
						const borrower = getBorrowerOptionByRole('Borrower')
						if (borrower) {
							borrowerOptionsFromTargetRole.push(borrower.value)
						}
						break
					case 'CoBorrower':
						const coBorrower = getBorrowerOptionByRole('CoBorrower')
						if (borrower) {
							borrowerOptionsFromTargetRole.push(coBorrower.value)
						}
						break
				}
				if (borrowerOptionsFromTargetRole.length > 0) {
					setValue('borrowers', borrowerOptionsFromTargetRole)
				}
			}
		}
	}, [borrowerOptions])

	return (
		<Form onSubmit={handleSubmit(onSubmit)} errors={apiErrors}>
			<FormRow>
				<TextField
					name="name"
					label="Question"
					helperText={`What ${type === 'Document' ? 'document' : 'information'} would you like the user to provide?`}
					className="w-full"
					required
					control={control}
					errors={errors}
				/>
			</FormRow>
			<FormRow>
				<TextField
					name="description"
					label="Description"
					helperText="Provide a user facing description explaining why this task is required"
					className="w-full"
					multiline
					rows={3}
					required
					control={control}
					errors={errors}
				/>
			</FormRow>
			<FormRow className="flex flex-col md:flex-row">
				<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
					<Select
						name="type"
						className="w-full"
						label="Type"
						helperText="Type of task (e.g. Question, Document)"
						menuItems={typeOptions}
						required
						control={control}
						errors={errors}
					/>
				</div>
				<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm md:pl-3">
					<TaskLosTargetField
						name="losTarget"
						loanId={loanId}
						type={type}
						control={control}
						errors={errors}
						required={type !== 'Field'}
					/>
				</div>
			</FormRow>
			<FormRow className="flex flex-col md:flex-row">
				<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
					{borrowerSelectEnabled ? (
						<Select
							name="borrowers"
							menuItems={borrowerOptions}
							label={`Borrower${borrowerOptions.length > 1 ? 's' : ''}`}
							fullWidth
							required
							control={control}
							errors={errors}
							selectProps={{ multiple: true }}
							disabled={task?.id || borrowerOptions.length === 1}
						/>
					) : (
						<Select
							name="targetUserRole"
							menuItems={userRoleOptions}
							label="User Role"
							fullWidth
							required
							disabled={
								task?.id &&
								![Roles.admin, Roles.superAdmin].includes(user.role)
							}
							control={control}
							errors={errors}
						/>
					)}
				</div>
				<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm md:pl-3">
					<NumberField
						name="daysDueFromApplication"
						className="w-full"
						label="Task Duration"
						helperText="Number of days until task is due after it's created"
						required
						control={control}
						errors={errors}
					/>
				</div>
			</FormRow>
			{[Roles.admin, Roles.superAdmin].includes(user.role) && (
				<FormRow>
					<Checkbox
						name="isGlobal"
						label="Global"
						control={control}
						errors={errors}
					/>
					<FormHelperText className="pl-8">
						Should this task be shared between business rules and
						loans?
					</FormHelperText>
				</FormRow>
			)}
			<FormRow className="flex flex-row justify-between items-center mt-3">
				{onCancel && (
					<div className="mr-2">
						<Button id="Cancel" onClick={onCancel} variant="outlined">
							Cancel
						</Button>
					</div>
				)}
				<LoadingBtn
					disabled={isSubmitting}
					loading={isSubmitting}
					type="submit"
					text="Save"
					fullWidth={false}
				/>
			</FormRow>
		</Form>
	)
}

export default TaskForm
