import React, { useEffect, useState } from 'react'
import { useVenti } from 'venti'
import PublishSubscribe from 'publish-subscribe-js'
import Page from '../../../components/Page'
import DataTable from '../../../components/DataTable'
import { useAppContext } from '../../../components/AppContext'
import { useAppContextActions } from '../../../components/AppContext/AppHooks'
import { getTheme } from '../../../config'
import { getDevices } from '../../../services/client'
import { getErrorMessage } from '../../../services/helper'
import { useAlert } from '../../../hooks'
import { generatePath, useNavigate } from 'react-router-dom'
import { navigationLinking } from '../../../services/navigation'

const theme = getTheme()

export default function AdminDevicesView() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state
	const ventiState = useVenti()
	const navigate = useNavigate()

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const [searchText, setSearchText] = useState('')

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'Serial #',
			selector: (row) => row.serialNumber,
			sortable: true,
		},
		{
			name: 'Device Type',
			selector: (row) => row.type,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => row.status,
		},
	]

	useEffect(() => {
		if (searchText !== '') {
			let newFilteredData = !searchText
				? data
				: data.filter(
						(d) =>
							d.name
								?.toLowerCase()
								.toString()
								.includes(searchText.toLowerCase()) ||
							d.serialNumber
								?.toLowerCase()
								.toString()
								.includes(searchText.toLowerCase())
					)
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(data)
		}
	}, [data, searchText])

	useEffect(() => {
		;(async () => {
			if (!user.isLoggedIn) await logOut()
			else await loadDevices(user.accountID)
		})()
	}, [user])

	useEffect(() => {
		PublishSubscribe.subscribe(
			'UPDATE_ADMIN_COMPONENT_STATE',
			async () => {
				await updateDevices()
			}
		)

		return () => {
			PublishSubscribe.unsubscribe('UPDATE_ADMIN_COMPONENT_STATE')
		}
	}, [])

	const loadDevices = async () => {
		try {
			setFetching(true)
			const devicesData = await getDevices(user.accountID)
			setData(devicesData.rows)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const updateDevices = async () => {
		const data = await getDevices(user.accountID)
		setData(data.rows)
	}

	const onRowClicked = (row) => {
		navigate(
			generatePath(`/${navigationLinking.AdminDeviceEdit}`, {
				id: row.id,
			})
		)
	}

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	return (
		<Page title="Loan Officers" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<DataTable
					data={filteredData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="name"
					pagination={true}
					progressPending={fetching}
					title="Kiosks"
					onRefreshClick={loadDevices}
					keyField="id"
					onRowClicked={onRowClicked}
					onSearchChange={onSearchChange}
					exportEnabled={false}
					fixedHeader={true}
					actionItems={[
						{
							name: 'Edit',
							onClick: (e, row) => onRowClicked(row),
						},
					]}
				/>
			</div>
		</Page>
	)
}
