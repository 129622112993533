import { useForm as reactHookUseForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const useForm = ({
	schema,
	mode = 'onBlur',
	reValidateMode = 'onChange',
	...rest
}) => {
	return reactHookUseForm({
		resolver: schema ? yupResolver(schema) : undefined,
		mode,
		reValidateMode: reValidateMode,
		...rest,
	})
}

export default useForm
