import React from 'react'
import { Dialog } from '@mui/material'
import { DeleteMeForm } from '../../forms/DeleteMeForm'

export const ModalDeleteMe = ({ modal, setModal, onSubmit }) => {
	const handleCloseModal = () => {
		setModal((current) => ({ ...current, visible: false }))
	}

	return (
		<Dialog
			open={modal?.visible}
			onClose={handleCloseModal}
			aria-labelledby="responsive-dialog-title"
			fullWidth
		>
			<div className="flex flex-col items-center px-6 md:max-w-xl py-8 md:px-10">
				<p className="pb-5 text-xl text-center font-bold">
					{modal.title}
				</p>
				<p className="pb-5 text-md">{modal.description}</p>

				<DeleteMeForm
					setModal={setModal}
					onSubmit={onSubmit}
					onClose={handleCloseModal}
				/>
			</div>
		</Dialog>
	)
}
