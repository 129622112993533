import React, { useState } from 'react'
import {
	FormControlLabel,
	FormHelperText,
	Switch,
} from '@mui/material'
import { useAppContext } from '../../../components/AppContext'
import { useTheme } from '@mui/material/styles'
import PublishSubscribe from 'publish-subscribe-js'
import { useAlert } from '../../../hooks'
import { getErrorMessage } from '../../../services/helper'
import ModalMobilePhoneVerificationModal from '../../../components/modals/ModalMobilePhoneVerificationModal'
import { LoadingBtn } from '../../../components/Button'
import useUser from '../../../hooks/useUser'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'

const UserProfileSettings = () => {
	const { alert } = useAlert()
	const theme = useTheme()
	const { state } = useAppContext()
	const { user, siteConfig } = state
	const { updateMe } = useUser()
	const mixpanel = useMixpanel()
	const themeMUI = useTheme()

	const [phoneModalOpen, setPhoneModalOpen] = React.useState(false)
	const [loading, setLoading] = useState(false)

	const changeMode = () => {
		const mode = themeMUI.palette.mode === 'light' ? 'dark' : 'light'
		mixpanel.trackEvent(eventTypes.USER_PROFILE_DARK_MODE_TOGGLED, {
			mode,
		})
		PublishSubscribe.publish('SET_DARK_MODE', mode)
	}

	const updateUserMFASetting = async (isChecked) => {
		try {
			setLoading(true)
			await updateMe({ ...user, mfaEnabled: isChecked })
			mixpanel.trackEvent(eventTypes.USER_PROFILE_MFA_TOGGLED, {
				mfaEnabled: isChecked,
			})
			setPhoneModalOpen(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		} finally {
			setLoading(false)
		}
	}

	const handleMFAChange = async (event) => {
		const isChecked = event.target.checked
		if (isChecked) {
			setPhoneModalOpen(true)
		} else {
			await updateUserMFASetting(isChecked)
		}
	}

	const onMobileDialogClose = () => {
		setPhoneModalOpen(false)
	}

	const onMFAConfirmationComplete = () => {
		setPhoneModalOpen(false)
	}

	const onUpdateMFAPhoneClick = () => {
		setPhoneModalOpen(true)
	}

	return (
		<>
			<ModalMobilePhoneVerificationModal
				open={phoneModalOpen}
				onClose={onMobileDialogClose}
				onComplete={onMFAConfirmationComplete}
			/>
			<div className="mb-2">
				<FormControlLabel
					label="Enable Dark Mode"
					control={
						<Switch
							id="themeMode"
							checked={theme.palette.mode === 'dark'}
							onChange={changeMode}
						/>
					}
				/>
			</div>
			{(siteConfig.mfaPreference !== 'Disabled' ||
				user.mfaEnabled) && (
				<div className="mb-2">
					<FormControlLabel
						label="Enable Multi-Factor Authentication (SMS)"
						control={
							<Switch
								checked={user.mfaEnabled}
								onChange={handleMFAChange}
								disabled={
									siteConfig.mfaPreference === 'Required' || loading
								}
							/>
						}
					/>
					{siteConfig.mfaPreference === 'Required' && (
						<FormHelperText>
							MFA is required for your organization
						</FormHelperText>
					)}
					{user.phoneVerified && user.mfaEnabled && (
						<div className="w-1/4 mt-2">
							<LoadingBtn
								text="Update Phone Number"
								onClick={onUpdateMFAPhoneClick}
							/>
						</div>
					)}
				</div>
			)}
		</>
	)
}

export default UserProfileSettings
