import React, { useEffect, useState } from 'react'
import { useVenti } from 'venti'
import {
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material'
import { StateLicenses } from '../../../components/admin/StateLicenses'
import { TextEditor } from '../../../components/formControls/TextEditor'
import {
	getBranchSiteConfiguration,
	getBranches,
	getCorporateLoanOfficers,
	getUserRelations,
} from '../../../services/client'
import { getErrorMessage, states } from '../../../services/helper'
import { applyMask } from '../../../services/utils'
import { getTheme } from '../../../config'
import { useAlert } from '../../../hooks'

const theme = getTheme()

export default function AdminPartnerProfile({
	formData,
	setFormData,
	width,
	user,
	siteConfig,
}) {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const [branches, setBranches] = useState([])
	const [loanOfficers, setLoanOfficers] = useState([])
	const [inValidFields, setInValidFields] = useState([])

	useEffect(() => {
		if (!branches) return
		;(async () => {
			try {
				const branch = branches.find(
					(branch) => branch.id === formData.branchID
				)
				if (branch) {
					const loanOfficers = await getCorporateLoanOfficers(
						branch.corporateID,
						true
					)
					setLoanOfficers(
						loanOfficers.sort((a, b) =>
							a.firstName > b.firstName ? 1 : -1
						)
					)
				}
			} catch (e) {
				console.log(e)
			}
		})()
	}, [branches])

	useEffect(() => {
		if (user?.isLoggedIn) {
			;(async () => {
				try {
					const initialLicenses = JSON.parse(
						JSON.stringify(formData.licenses)
					)
					const branchesData = await getBranches()
					const branches = []

					branchesData.forEach((item) => {
						item?.siteConfigurations.forEach((site) => {
							branches.push({
								...site,
								id: item.id,
								siteConfigurationId: site.id,
								corporateID: item.corporateID,
							})
						})
					})

					setBranches(
						branches.sort((a, b) => (a.name > b.name ? 1 : -1))
					)

					let domain
					if (formData.branchID) {
						const branch = branches.find(
							(b) => b.id === formData.branchID
						)
						const parts = branch.url.split('.')
						parts.shift()
						domain = parts.join('.')
					}

					setFormData((formData) => ({
						...formData,
						domain,
						licenses: initialLicenses,
						introductionTitle:
							formData.introductionTitle ||
							`Who is ${formData?.name || '...'}?`,
					}))
				} catch (e) {
					alert(getErrorMessage(e), { severity: 'error' })
					ventiState.set(
						theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error
					)
				}
			})()
		}
	}, [user])

	useEffect(() => {
		if (formData.branchID) {
			;(async () => {
				try {
					const branch = branches.find(
						(branch) => branch.id === formData.branchID
					)
					if (branch) {
						const loanOfficers = await getCorporateLoanOfficers(
							branch.corporateID,
							true
						)
						setLoanOfficers(
							loanOfficers.sort((a, b) =>
								a.firstName > b.firstName ? 1 : -1
							)
						)
					}
				} catch (e) {
					console.log(e)
				}
			})()
		}
	}, [formData.branchID])

	useEffect(() => {
		if (formData.entityID)
			(async () => {
				try {
					const relations = await getUserRelations(formData.entityID)
					const loanOfficerRelation = relations.find(
						(r) => r.relationType === 'RealtorLoanOfficer'
					)
					if (loanOfficerRelation) {
						setFormData((current) => ({
							...current,
							loanOfficerRelation,
							loanOfficerID: loanOfficerRelation.userId2,
						}))
					}
				} catch (e) {
					console.log(e)
				}
			})()
	}, [formData.entityID])

	const updatePOSSiteConfiguration = (field, value) => {
		setFormData((formData) => ({ ...formData, [field]: value }))
	}

	const updateFirstName = (text) =>
		setFormData((formData) => ({
			...formData,
			firstName: text,
			name: `${text} ${formData.lastName}`,
		}))
	const updateLastName = (text) =>
		setFormData((formData) => ({
			...formData,
			lastName: text,
			name: `${formData.firstName} ${text}`,
		}))
	const updateEmail = (text) =>
		setFormData((formData) => ({ ...formData, email: text }))
	const updateTitle = (text) =>
		setFormData((formData) => ({ ...formData, title: text }))
	const updateBioTitle = (text) =>
		setFormData((formData) => ({
			...formData,
			introductionTitle: text,
		}))
	const updateSiteName = (text) =>
		setFormData((formData) => ({ ...formData, name: text }))

	const handleIntroductionChange = (newIntroduction) => {
		if (newIntroduction !== formData.introduction) {
			setFormData({
				...formData,
				introduction: newIntroduction,
			})
		}
	}

	const updateBranchId = async (branchId) => {
		if (branchId) {
			const branch = branches.find((b) => b.id === branchId)
			const parts = branch.url.split('.')
			parts.shift()
			const domain = parts.join('.')
			setFormData((formData) => ({
				...formData,
				branchID: branchId,
				domain,
			}))
		} else {
			setFormData((formData) => ({
				...formData,
				branchID: '',
				domain: '',
			}))
		}

		if (!formData.id && branchId) {
			try {
				const branch = branches.find((b) => b.id === branchId)
				const branchSiteConfiguration =
					await getBranchSiteConfiguration(
						branchId,
						branch.siteConfigurationId
					)

				const {
					id,
					entityID,
					entityType,
					url,
					email,
					name,
					phone,
					fax,
					tollFree,
					licenses,
					nmlsid,
					introduction,
					introductionTitle,
					losUserID,
					eppsUserName,
					enabledServicesID,
					address,
					address2,
					city,
					state,
					zip,
					...inheritedSiteConfiguration
				} = branchSiteConfiguration

				setFormData((formData) => ({
					...formData,
					...inheritedSiteConfiguration,
				}))
			} catch (e) {
				alert(getErrorMessage(e), { severity: 'error' })
				ventiState.set(
					theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error
				)
			}
		}
	}

	const updateLoanOfficerId = async (loanOfficerID) => {
		setFormData((formData) => ({
			...formData,
			loanOfficerChanged: true,
			loanOfficerID,
		}))
	}

	const handleInputValid = (id) => {
		return inValidFields.includes(id)
	}

	const handleOnChangeText = (text, nativeID, field, action) => {
		if (field === 'phone' || field === 'tollFree') {
			text = applyMask(text, 'phone')
		}

		if (action) action(text)
		if (field) updatePOSSiteConfiguration(field, text.trim())

		// Email Validation
		//const emailReg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

		if (text) {
			setInValidFields(
				inValidFields.filter((item) => item !== nativeID)
			)
		} else {
			setInValidFields([nativeID, ...inValidFields])
			handleInputValid(text)
		}
	}

	const handleStateLicensesSelect = (selectedState) => {
		let licenses = formData.licenses
		if (selectedState === 'all')
			licenses = states.map((state) => state.value)
		else if (selectedState === 'none') licenses = []
		else {
			const isChecked = licenses.includes(selectedState)
			if (isChecked)
				licenses = formData.licenses.filter(
					(l) => l !== selectedState
				)
			else licenses.push(selectedState)
		}

		setFormData((formData) => ({ ...formData, licenses }))
	}

	return (
		<div className="flex flex-col md:flex-row">
			<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
				<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">
					Branch *
				</p>
				<div className="flex flex-row w-full items-end">
					<FormControl
						key={'AdminLoanOfficerProfileBranchPicker'}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
						error={handleInputValid(
							'AdminAccountProfileSiteUrlInput'
						)}
					>
						{!formData.branchID && (
							<InputLabel>Select a Branch...</InputLabel>
						)}
						<Select
							value={formData.branchID || ''}
							onChange={(e) => updateBranchId(e.target.value)}
							label="Landing Page Type"
						>
							<MenuItem value="" key="none">
								Select a Branch
							</MenuItem>
							{branches.map((option, index) => (
								<MenuItem key={index} value={option.id}>
									{option.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>

				<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">
					Loan Officer *
				</p>
				<div className="flex flex-row w-full items-end">
					<FormControl
						key={'AdminLoanOfficerProfileBranchPicker'}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
						error={handleInputValid(
							'AdminAccountProfileSiteUrlInput'
						)}
					>
						{!formData.loanOfficerID && (
							<InputLabel>Select a Loan Officer...</InputLabel>
						)}
						<Select
							value={formData.loanOfficerID || ''}
							onChange={(e) => updateLoanOfficerId(e.target.value)}
							label="Loan Officer"
						>
							<MenuItem value="" key="none">
								Select a Loan Officer
							</MenuItem>
							{loanOfficers.map((option, index) => (
								<MenuItem key={index} value={option.id}>
									{option.firstName} {option.lastName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminLoanOfficerProfileSiteUrlInput`}
						placeholder={`* Site Url (Ex: loanofficer.${formData.domain || siteConfig.url.replace(/^[^.]+\./g, '')})`}
						label="The Big POS Landing Page Url *"
						value={formData.url || ''}
						onChange={(e) =>
							handleOnChangeText(
								e.target.value,
								'AdminLoanOfficerProfileSiteUrlInput',
								'url'
							)
						}
						error={handleInputValid(
							'AdminLoanOfficerProfileSiteUrlInput'
						)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminLoanOfficerProfileSiteNameInput`}
						placeholder="Site Name (required)"
						label="Site Name *"
						value={formData.name || ''}
						onChange={(e) =>
							handleOnChangeText(
								e.target.value,
								'AdminLoanOfficerProfileSiteNameInput',
								null,
								updateSiteName
							)
						}
						error={handleInputValid(
							'AdminLoanOfficerProfileSiteNameInput'
						)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div
					className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
				>
					<TextField
						id={`AdminLoanOfficerProfileFirstNameInput`}
						placeholder="First Name (required)"
						value={formData?.firstName || ''}
						label="First Name *"
						onChange={(e) =>
							handleOnChangeText(
								e.target.value,
								'AdminLoanOfficerProfileFirstNameInput',
								null,
								updateFirstName
							)
						}
						error={handleInputValid(
							'AdminLoanOfficerProfileFirstNameInput'
						)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminLoanOfficerProfileLastNameInput`}
						placeholder="Last Name (required)"
						value={formData?.lastName || ''}
						label="Last Name *"
						onChange={(e) =>
							handleOnChangeText(
								e.target.value,
								'AdminLoanOfficerProfileLastNameInput',
								null,
								updateLastName
							)
						}
						error={handleInputValid(
							'AdminLoanOfficerProfileLastNameInput'
						)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div
					className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
				>
					<TextField
						id={`AdminLoanOfficerProfileEmailInput`}
						placeholder="Email Address (required)"
						value={formData?.email || ''}
						label="Email Address *"
						onChange={(e) =>
							handleOnChangeText(
								e.target.value,
								'AdminLoanOfficerProfileEmailInput',
								null,
								updateEmail
							)
						}
						error={handleInputValid(
							'AdminLoanOfficerProfileEmailInput'
						)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminLoanOfficerProfileNMLSIDInput`}
						placeholder="NMLS ID (required)"
						value={formData.nmlsid}
						label="NMLS ID *"
						onChange={(e) =>
							handleOnChangeText(
								e.target.value,
								'AdminLoanOfficerProfileNMLSIDInput',
								'nmlsid'
							)
						}
						error={handleInputValid(
							'AdminLoanOfficerProfileNMLSIDInput'
						)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div
					className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
				>
					<TextField
						id={`AdminLoanOfficerProfilePhoneInput`}
						placeholder="Mobile Phone"
						value={formData.phone}
						label="Mobile Phone *"
						onChange={(e) =>
							handleOnChangeText(
								e.target.value,
								'AdminLoanOfficerProfilePhoneInput',
								'phone'
							)
						}
						error={handleInputValid(
							'AdminLoanOfficerProfilePhoneInput'
						)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminLoanOfficerProfileTollFreeInput`}
						placeholder="Office Phone"
						value={formData?.tollFree}
						label="Office Phone *"
						onChange={(e) =>
							handleOnChangeText(
								e.target.value,
								'AdminLoanOfficerProfileTollFreeInput',
								'tollFree'
							)
						}
						error={handleInputValid(
							'AdminLoanOfficerProfileTollFreeInput'
						)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div
					className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
				>
					<TextField
						id={`AdminLoanOfficerProfileFaxInput`}
						placeholder="Fax"
						value={formData.fax}
						label="Fax"
						onChange={(e) =>
							updatePOSSiteConfiguration('fax', e.target.value)
						}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminLoanOfficerProfileTitleInput`}
						placeholder="Title"
						value={formData.title || ''}
						label="Title *"
						onChange={(e) =>
							handleOnChangeText(
								e.target.value,
								'AdminLoanOfficerProfileTitleInput',
								null,
								updateTitle
							)
						}
						error={handleInputValid(
							'AdminLoanOfficerProfileTitleInput'
						)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
			</div>

			<div className="w-full md:ml-5 md:w-1/2 md:mx-2 md:mb-0 md:max-w-screen-sm">
				<TextField
					id={`AdminLoanOfficerBioTitleInput`}
					placeholder="Loan Officer Bio Title"
					value={formData.introductionTitle || ''}
					label="Loan Officer Bio Title"
					onChange={(e) =>
						handleOnChangeText(
							e.target.value,
							'AdminLoanOfficerBioTitleInput',
							null,
							updateBioTitle
						)
					}
					variant="standard"
					className="w-full sm:px-12"
					sx={{ mb: 2 }}
				/>

				<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">
					Loan Officer Bio
				</p>
				<TextEditor
					placeholder="Type something..."
					initialValue={formData.introduction}
					onChange={handleIntroductionChange}
				/>
				<StateLicenses
					Licenses={formData.licenses}
					handleStateLicensesSelect={handleStateLicensesSelect}
				/>
			</div>
		</div>
	)
}
