import React, { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../services/queryKeys'
import {
	getDocumentBuckets,
	getLoanDocumentBuckets,
} from '../../../services/client'
import {
	Autocomplete,
	CircularProgress,
	TextField,
} from '@mui/material'

const DocumentBucketAutocomplete = ({
	onChange,
	error,
	helperText,
	required,
	loanId,
	label = 'Document Bucket',
	value,
	...rest
}) => {
	const [bucket, setBucket] = useState(value)

	const {
		isFetching,
		isRefetching,
		isError,
		data: buckets,
	} = useQuery({
		queryKey: loanId
			? [queryKeys.documentBuckets]
			: [queryKeys.loanDocumentBuckets, loanId],
		queryFn: () =>
			loanId ? getLoanDocumentBuckets(loanId) : getDocumentBuckets(),
	})

	const labelTxt = useMemo(
		() => (required ? `${label} *` : label),
		[required, label]
	)

	const handleChange = (event, value) => {
		setBucket(value)
		if (onChange && bucket !== value) {
			onChange(value)
		}
	}
	const loading = useMemo(
		() => isFetching || isRefetching,
		[isFetching, isRefetching]
	)

	const bucketOptions = useMemo(() => {
		if (!buckets) return []
		return buckets.map((bucket) => ({
			label: bucket,
			value: bucket,
		}))
	}, [buckets])

	return (
		<Autocomplete
			{...rest}
			id="document-bucket-autocomplete"
			value={bucket}
			options={bucketOptions || []}
			loading={isFetching || isRefetching}
			onChange={handleChange}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.value}>
						{option.label}
					</li>
				)
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="standard"
					label={labelTxt}
					error={isError || error}
					helperText={
						isError ? 'Error loading document buckets' : helperText
					}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? (
									<CircularProgress color="inherit" size={18} />
								) : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	)
}

export default DocumentBucketAutocomplete
