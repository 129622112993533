import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useVenti } from 'venti'
import {
	Collapse,
	Divider,
	IconButton,
	Link,
	List,
	ListItemButton,
	ListItemText,
	Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import DownloadIcon from '@mui/icons-material/Download'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { useAppContext } from '../../components/AppContext'
import Page from '../../components/Page'
import { ModalDocumentType } from '../../components/modals/ModalDocumentType'
import { ModalUploadSizeLimit } from '../../components/modals/ModalUploadSizeLimit'
import { ModalDocumentDragOrUpload } from '../../components/modals/ModalDocumentDragOrUpload'
import { Loading } from '../../components/Loading'
import { Button } from '../../components/Button'
import { getTheme } from '../../config'
import {
	createLoanDocument,
	getLoanDocumentContent,
	getLoanDocumentContentUrl,
	getLoanDocuments,
	Roles,
} from '../../services/client'
import {
	getErrorMessage,
	isMobileDevice,
} from '../../services/helper'
import { fileMBSizes } from '../../services/utils'
import { useAlert, useWindowSize } from '../../hooks'
import RefreshPNG from 'assets/lordicons/Refresh.png'
import RefreshGIF from 'assets/lordicons/RefreshAnimated.gif'
import RefreshDarkPNG from 'assets/lordicons/RefreshDark.png'
import RefreshDarkGIF from 'assets/lordicons/RefreshAnimatedDark.gif'
import { ModalUploadMimeType } from '../../components/modals/ModalUploadMimeType'
import { useMixpanel } from '../../hooks/useMixpanel'
import { eventTypes } from '../../services/constants'

const theme = getTheme()

const bgColor = {
	'&:hover': {
		backgroundColor: 'transparent',
	},
}

export default function Documents() {
	const DEFAULT_BUCKET = 'Borrower Portal Document Vault'
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { user } = state
	const [width] = useWindowSize()
	const themeMUI = useTheme()
	const mixpanel = useMixpanel()
	const [loanDocuments, setLoanDocuments] = useState([])
	const [documentFolders, setDocumentFolders] = useState([])

	const ventiState = useVenti()
	const { loanId } = useParams()
	const preloadedDocs = ventiState.get(theme.storageKeys.loanDocs)
	const openDocFoldersState = ventiState.get(theme.openDocFolders)
	const preloadedDocument = ventiState.get(theme.storageKeys.document)

	const [fetchingDocuments, setFetchingDocuments] = useState(false)
	const [fetchingTasks, setFetchingTasks] = useState(false)
	const [uploadDocumentType, setUploadDocumentType] = useState(null)
	const [document, setDocument] = useState(null)
	const [documentTypeModalVisible, setDocumentTypeModalVisible] =
		useState(false)
	const [documentUploadModalVisible, setDocumentUploadModalVisible] =
		useState(false)
	const [tasksToComplete, setTasksToComplete] = useState([])
	const [errorModalVisible, setErrorModalVisible] = useState(false)
	const [uploadDocumentSuccess, setUploadDocumentSuccess] =
		useState(false)
	const [modalSizeLimitVisible, setModalSizeLimitVisible] =
		useState(false)
	const [modalMimeTypeVisible, setModalMimeTypeVisible] =
		useState(false)
	const [uploading, setUploading] = useState(false)
	const [viewDocument, setViewDocument] = useState(null)
	const [openMainFolders, setOpenMainFolders] = useState([
		'documents',
	])
	const [openDocFolders, setOpenDocFolders] = useState([])
	const [documentPreviewFolders, setDocumentPreviewFolders] =
		useState([])

	useEffect(() => {
		if (openDocFoldersState) {
			setOpenDocFolders([openDocFoldersState] || [])
			setOpenMainFolders(['documents'])
		}
	}, [openDocFoldersState])

	useEffect(() => {
		if (loanId) {
			fetchDocuments(loanId, user, preloadedDocs, false)
		}
	}, [loanId, user, preloadedDocs])

	const refreshDocuments = () => {
		if (loanId) {
			fetchDocuments(loanId, user, preloadedDocs, true)
		}
	}

	const fetchDocuments = async (
		loanId,
		user,
		preloadedDocs,
		refresh = true
	) => {
		setFetchingDocuments(true)
		setOpenDocFolders([])
		setDocumentPreviewFolders([])

		try {
			let docResponse
			if (preloadedDocs && !refresh) docResponse = preloadedDocs
			else docResponse = await getLoanDocuments(loanId)
			setLoanDocuments(docResponse)
			const folders = {}
			docResponse.forEach((row) => {
				folders[row.documentBucketTitle] = true
			})

			setDocumentFolders(Object.keys(folders).sort())
			setFetchingDocuments(false)
		} catch (e) {
			setFetchingDocuments(false)
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message ||
					`There was an error fetching the loan tasks. Please contact ${[Roles.loanOfficer, Roles.branchManager].includes(user.role) ? 'support' : 'your loan officer'}.`
			)
		}
	}

	useEffect(() => {
		if (preloadedDocument?.base64Data && loanDocuments.length) {
			clickFolder(preloadedDocument.documentBucketTitle, 'doc')
			handleDocumentPress(preloadedDocument)
		}
	}, [loanDocuments, preloadedDocument])

	const clickFolder = (id, type) => {
		if (type === 'main') {
			if (openMainFolders.includes(id)) {
				setOpenMainFolders(
					openMainFolders.filter((folderId) => folderId !== id)
				)
			} else {
				setOpenMainFolders((prevOpenMainFolders) => [
					...prevOpenMainFolders,
					id,
				])
			}
		} else if (type === 'doc') {
			if (openDocFolders.includes(id)) {
				setOpenDocFolders(
					openDocFolders.filter((folderId) => folderId !== id)
				)
			} else {
				setOpenDocFolders((prevOpenDocFolders) => [
					...prevOpenDocFolders,
					id,
				])
			}
		}
	}

	const handleDocumentPress = (doc) => {
		setViewDocument(doc)
		const { documentID } = doc
		mixpanel.trackEvent(eventTypes.DOCUMENT_TEMPLATE_PREVIEWED, {
			id: documentID,
		})
		// if (isMobileDevice()) window.open(getLoanDocumentContentUrl({ loanId, documentId: doc.documentID }))
	}

	const pickDocument = async (docType) => {
		if (docType) setUploadDocumentType(docType)

		if (docType?.target?.files?.length > 0) {
			let result = docType.target.files[0]

			if (
				!theme.validation.allowedDocumentTypes.includes(result.type)
			) {
				return setModalMimeTypeVisible(true)
			}

			if (result?.size > fileMBSizes['20']) {
				return setModalSizeLimitVisible(true)
			}

			await uploadDocument(loanId, result)
		}
	}

	const downloadPDF = async (doc) => {
		const { documentID } = doc
		mixpanel.trackEvent(eventTypes.DOCUMENT_TEMPLATE_DOWNLOADED, {
			id: documentID,
		})
		return window.open(
			getLoanDocumentContentUrl({
				loanId,
				documentId: doc.documentID,
			})
		)
	}

	const uploadDocument = async (loanId, doc) => {
		try {
			setUploading(true)
			await createLoanDocument(loanId, {
				name: doc.name,
				file: doc,
				bucket: uploadDocumentType || DEFAULT_BUCKET,
			})
			setDocumentUploadModalVisible(false)
			setUploading(false)
			setUploadDocumentSuccess(true)
			setDocumentTypeModalVisible(true)
			// setUploadsInProcess(current => current.map(upload => {
			// 	if (upload.id === uploadId) return { ...upload, status: "success"}
			// 	return upload
			// }))
			await refreshDocuments()
		} catch (e) {
			// setUploadsInProcess(current => current.map(upload => {
			// 	if (upload.id === uploadId) return { ...upload, status: "error"}
			// 	return upload
			// }))
			alert(getErrorMessage(e), { severity: 'error' })
			setDocumentUploadModalVisible(false)
			setUploading(false)
			onCancelDocumentTypeModal()
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message ||
					`There was an error uploading the document. Please contact ${[Roles.loanOfficer, Roles.branchManager].includes(user.role) ? 'support' : 'your loan officer'}.`
			)
		}
	}

	const renderDocIframePreview = (doc) => {
		if (!doc.previewLoaded)
			return (
				<p className="m-auto text-center dark:text-white">
					Loading preview
				</p>
			)

		return (
			<img
				alt={doc.documentName}
				src={`data:application/${doc.extension};base64,${doc.base64Data}`}
				style={{ flex: 1 }}
			/>
		)
	}

	const onCancelDocumentTypeModal = () => {
		setDocument(null)
		setUploadDocumentType(null)
		setDocumentTypeModalVisible(false)
	}

	const onCancelUploadDocumentModal = () => {
		setDocument(null)
		setDocumentUploadModalVisible(false)
	}

	useEffect(() => {
		openDocFolders.forEach((folder) => {
			if (!documentPreviewFolders.includes(folder)) {
				setDocumentPreviewFolders((current) => [...current, folder])
				for (let doc of loanDocuments) {
					if (doc.documentBucketTitle === folder) {
						getLoanDocumentContent({
							loanId,
							documentId: doc.documentID,
						}).then((bas64Data) => {
							setLoanDocuments((current) =>
								current.map((c) => {
									if (c.documentID === doc.documentID) {
										c.base64Data = bas64Data
										c.previewLoaded = true
									}
									return c
								})
							)
						})
					}
				}
			}
		})
	}, [openDocFolders, loanDocuments])

	return (
		<>
			<ModalDocumentType
				visible={documentTypeModalVisible}
				tasksToComplete={tasksToComplete}
				setDocumentTypeModalVisible={setDocumentTypeModalVisible}
				uploadDocumentType={uploadDocumentType}
				setUploadDocumentType={setUploadDocumentType}
				confirmModal={uploadDocument}
				cancelModal={onCancelDocumentTypeModal}
				errorModalVisible={errorModalVisible}
				setErrorModalVisible={setErrorModalVisible}
				setUploadDocumentSuccess={setUploadDocumentSuccess}
				uploadDocumentSuccess={uploadDocumentSuccess}
				loadingDocument={true}
			/>

			<ModalDocumentDragOrUpload
				visible={documentUploadModalVisible}
				uploading={uploading}
				pickDocument={pickDocument}
				cancelModal={onCancelUploadDocumentModal}
				bucketName={uploadDocumentType || DEFAULT_BUCKET}
			/>

			<ModalUploadSizeLimit
				visible={modalSizeLimitVisible}
				closeModal={() => setModalSizeLimitVisible(false)}
			/>

			<ModalUploadMimeType
				visible={modalMimeTypeVisible}
				closeModal={() => setModalMimeTypeVisible(false)}
			/>

			<Page page="documents" title="Documents" isFullWidth={true}>
				<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
					<p className="flex items-center text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
						<div>Documents</div>
						<div className="ml-3">
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="refresh"
								onClick={refreshDocuments}
								disabled={fetchingDocuments || fetchingTasks}
								sx={bgColor}
							>
								<img
									className="w-14 mt-1"
									src={
										themeMUI.palette.mode === 'light'
											? RefreshPNG
											: RefreshDarkPNG
									}
									onMouseOver={(e) =>
										(e.currentTarget.src =
											themeMUI.palette.mode === 'light'
												? RefreshGIF
												: RefreshDarkGIF)
									}
									onMouseOut={(e) =>
										(e.currentTarget.src =
											themeMUI.palette.mode === 'light'
												? RefreshPNG
												: RefreshDarkPNG)
									}
									alt="refresh"
								/>
							</IconButton>
						</div>
					</p>
					{(fetchingDocuments || fetchingTasks) && (
						<div className="flex justify-center w-full h-full">
							<Loading size="small" />
						</div>
					)}
					{!fetchingDocuments && (
						<div className="flex flex-col w-full border border-slate-500 mt-5">
							<div
								className={`${width > 768 ? 'flex-row' : 'flex-col'} flex`}
							>
								<div
									className={`${width > 768 ? 'max-w-xs' : 'w-full'} grow border border-slate-500`}
								>
									<List>
										<ListItemButton
											className="dark:text-white"
											divider
											onClick={() => clickFolder('documents', 'main')}
										>
											<ListItemText
												primary={`Document Folders (${documentFolders.length})`}
											/>
											{openMainFolders.includes('documents') ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)}
										</ListItemButton>
										<Collapse
											in={openMainFolders.includes('documents')}
											timeout="auto"
											unmountOnExit
										>
											{documentFolders.map((folder, index) => (
												<List
													component="div"
													disablePadding
													key={index}
												>
													<div className="flex bg-blue-100 dark:bg-slate-800">
														<IconButton
															style={{
																backgroundColor: 'transparent',
															}}
															onClick={() => {
																setDocumentUploadModalVisible(true)
																setUploadDocumentType(folder)
															}}
															aria-label="comment"
														>
															<CloudUploadOutlinedIcon />
														</IconButton>
														<Divider
															orientation="vertical"
															flexItem
														/>
														<ListItemButton
															className="dark:text-white"
															onClick={() =>
																clickFolder(folder, 'doc')
															}
														>
															<ListItemText
																primary={
																	folder.length <= 35
																		? folder
																		: `${folder.slice(0, 35)}...`
																}
															/>
															{openDocFolders.includes(folder) ? (
																<ExpandLess />
															) : (
																<ExpandMore />
															)}
														</ListItemButton>
													</div>
													<Collapse
														in={openDocFolders.includes(folder)}
														timeout="auto"
														unmountOnExit
													>
														<List component="div" disablePadding>
															{loanDocuments.map((doc, index) => {
																if (
																	doc.documentBucketTitle === folder
																) {
																	return (
																		<div
																			className="flex- flex-col w-full text-center bg-slate-200 dark:bg-transparent"
																			key={index}
																		>
																			<p className="pt-2 pb-2 dark:text-white">
																				ATTACHED FILES
																			</p>
																			<Divider />
																			<div className="h-20 flex justify-center bg-slate-200 dark:bg-slate-800">
																				<div className="break-all flex flex-col justify-center w-full m-2.5 h-16 px-2.5 py-1.5 bg-white rounded-md dark:bg-[#121212]">
																					<Link
																						onClick={() =>
																							handleDocumentPress(doc)
																						}
																						tabIndex={0}
																						component="button"
																						underline="none"
																					>
																						{doc.documentName.length >
																						36
																							? doc.documentName.slice(
																									0,
																									36
																								) + '...'
																							: doc.documentName}
																					</Link>
																					<p className="text-sm text-slate-400">
																						{new Date(
																							doc.createdAt
																						).toLocaleString()}
																					</p>
																				</div>
																			</div>
																		</div>
																	)
																}
															})}
														</List>
													</Collapse>
												</List>
											))}
										</Collapse>
									</List>
								</div>
								<div
									className={`${width > 768 ? 'w-48' : 'w-full items-center justify-center flex flex-wrap'} border border-slate-500`}
								>
									{openDocFolders.length === 0 && (
										<p className="m-2.5 text-center dark:text-white">
											Select a folder to preview
										</p>
									)}
									{openDocFolders[0] &&
										loanDocuments.filter((doc) =>
											openDocFolders.includes(doc.documentBucketTitle)
										).length === 0 && (
											<p className="dark:text-white">
												No documents were found in this folder
											</p>
										)}
									{loanDocuments
										.filter((doc) =>
											openDocFolders.includes(doc.documentBucketTitle)
										)
										.map((doc) => (
											<div key={doc.documentName} className="mx-auto">
												<div
													onClick={() => handleDocumentPress(doc)}
													className="mx-auto w-36 h-48 my-2.5 p-2 border border-slate-500 rounded-md hover:cursor-pointer hover:border-black hover:dark:border-white"
												>
													{renderDocIframePreview(doc)}
												</div>
												<div className="mb-4 font-bold text-center">
													<Tooltip title="Download">
														<IconButton
															size="large"
															edge="start"
															color="inherit"
															aria-label="Download"
															onClick={() => downloadPDF(doc)}
														>
															<DownloadIcon
																sx={{
																	color:
																		themeMUI.palette.mode === 'dark'
																			? '#fff'
																			: '#000',
																}}
															/>
														</IconButton>
													</Tooltip>
													{doc.documentName &&
													doc.documentName.length > 16 ? (
														<Tooltip title={doc.documentName}>
															<span onClick={() => downloadPDF(doc)}>
																{doc.documentName.slice(0, 16) +
																	'...'}
															</span>
														</Tooltip>
													) : (
														<span onClick={() => downloadPDF(doc)}>
															{doc.documentName}
														</span>
													)}
												</div>
											</div>
										))}
								</div>

								{
									<div className="flex grow border border-slate-500 p-1.5 overflow-auto">
										<div
											style={{ height: `calc(100vh - 110px)` }}
											className={`${viewDocument?.base64Data ? '' : 'items-center justify-center'} flex w-full flex-1`}
										>
											{!viewDocument?.documentID ||
											!viewDocument?.previewLoaded ? (
												<div className="flex flex-col">
													<p className="text-center dark:text-white">
														{!viewDocument?.documentID
															? 'Please select a document from the list or'
															: 'Loading file content...'}
														&nbsp;
													</p>
													{!viewDocument?.documentID && (
														<Link
															onClick={() =>
																setDocumentUploadModalVisible(true)
															}
															tabIndex={0}
															component="button"
															underline="none"
														>
															Upload a Document
														</Link>
													)}
												</div>
											) : (
												<div
													className="break-all flex flex-col w-full show-on-hover"
													style={{ height: `calc(100vh - 110px)` }}
												>
													{!isMobileDevice() && (
														<div className="self-end mb-2">
															<Button
																text="Download"
																onClick={() =>
																	downloadPDF(viewDocument)
																}
															/>
														</div>
													)}
													<div
														style={{ height: `calc(100vh - 110px)` }}
													>
														{renderDocIframePreview(viewDocument)}
													</div>
													{isMobileDevice() && (
														<div className="mb-4 font-bold text-center">
															<Tooltip title="Download">
																<IconButton
																	size="large"
																	edge="start"
																	color="inherit"
																	aria-label="Download"
																	onClick={() =>
																		downloadPDF(viewDocument)
																	}
																>
																	<DownloadIcon
																		sx={{
																			color:
																				themeMUI.palette.mode ===
																				'dark'
																					? '#fff'
																					: '#000',
																		}}
																	/>
																</IconButton>
															</Tooltip>
															<span
																onClick={() =>
																	downloadPDF(viewDocument)
																}
															>
																{viewDocument.documentName}
															</span>
														</div>
													)}
												</div>
											)}
										</div>
									</div>
								}
							</div>
						</div>
					)}
				</div>
			</Page>
		</>
	)
}
