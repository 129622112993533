import React from 'react'
import { states } from '../services/helper'

const StateLicensesList = ({ licenses }) => {
	return (
		<ul>
			{licenses.map((abbr) => {
				const state = states.find((s) => s.value === abbr)
				return (
					<li key={abbr} className="my-1">
						{state?.label || abbr}
					</li>
				)
			})}
		</ul>
	)
}

export default StateLicensesList
