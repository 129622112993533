import LoadingButton from '@mui/lab/LoadingButton'

export const LoadingBtn = ({
	text,
	id,
	onClick,
	disabled,
	loading,
	variant = 'contained',
	fullWidth = true,
	style,
	className,
	indicatorOnlyOnLoading = false,
	...rest
}) => (
	<LoadingButton
		id={id}
		disabled={disabled}
		loading={loading}
		onClick={onClick}
		fullWidth={fullWidth}
		variant={indicatorOnlyOnLoading && loading ? 'standard' : variant}
		className={className}
		style={{
			textTransform: 'none',
			fontSize: '14px',
			width: indicatorOnlyOnLoading && loading ? '65px' : null,
			height: indicatorOnlyOnLoading && loading ? '30px' : null,
			...style,
		}}
		{...rest}
	>
		{text}
	</LoadingButton>
)
