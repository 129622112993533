import React, { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useWindowSize } from '../../hooks/useWindowSize'
import { isBoolean } from 'lodash'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

export const TableMobileSort = ({
	entity = '',
	sortLoanApplication,
}) => {
	const [width] = useWindowSize()
	const [filterLoanApplication, setFilterLoanApplication] =
		useState('')
	const [sortData, setSortData] = useState([
		{ id: 1, name: 'First Name', fieldName: 'firstName' },
		{ id: 2, name: 'Last Name', fieldName: 'lastName' },
		{ id: 3, name: 'Loan Amount', fieldName: 'loanAmount' },
		{ id: 4, name: 'Application Date', fieldName: 'applicationDate' },
	])

	const onChangeFilter = (event) => {
		const value = event.target.dataset.value

		if (!value || value === '') {
			return
		}

		switch (entity) {
			case 'LoanApplication':
				let sortAscending
				const sortList = [...sortData].map((item) => {
					if (item.fieldName === value) {
						sortAscending =
							typeof item.asc === undefined ? false : !item.asc
						item.asc = sortAscending
					} else {
						delete item.asc
					}

					return item
				})

				setFilterLoanApplication(value)
				setSortData(sortList)
				sortLoanApplication(value, sortAscending)
				break
			default:
				return
		}
	}

	const showIcon = (sort) => {
		if (isBoolean(sort.asc)) {
			if (sort.asc === true) {
				return (
					<span className="ml-1">
						<ArrowDownwardIcon style={{ fontSize: 18 }} />
					</span>
				)
			}

			return (
				<span className="ml-1">
					<ArrowUpwardIcon style={{ fontSize: 18 }} />
				</span>
			)
		}

		return null
	}

	return (
		<div className="flex flex-row">
			{entity === 'LoanApplication' && (
				<FormControl
					sx={width > 767 ? { width: 260 } : { width: 280, mb: 3 }}
				>
					<Select
						value={filterLoanApplication}
						displayEmpty
						onClick={(event) => onChangeFilter(event)}
					>
						<MenuItem value="" key="none">
							- Sort by -
						</MenuItem>
						{sortData.map((sort, index) => (
							<MenuItem value={sort.fieldName} key={index}>
								{sort.name} {showIcon(sort)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</div>
	)
}
