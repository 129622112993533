import React, { useMemo } from 'react'
import LoanMilestones from './LoanMilestones'
import Heading from '../Heading'
import {
	applyMask,
	daysDiff,
	getEncompassDateString,
} from '../../services/utils'
import useLoanStatus from '../../hooks/useLoanStatus'
import { Loading } from '../Loading'
import MonthlyPaymentChart from './MonthlyPaymentChart'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { TableCell } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import { useWindowSize } from '../../hooks'

const LoanStatus = ({ loan }) => {
	const NA = 'N/A'
	const estClosingDateStr = useMemo(
		() => getEncompassDateString(loan.EstimatedClosingDate),
		[loan]
	)
	const actualClosingDateStr = useMemo(
		() => getEncompassDateString(loan.ClosingDate),
		[loan]
	)
	const daysToClosing = useMemo(() => {
		return estClosingDateStr || actualClosingDateStr
			? daysDiff(
					new Date(),
					actualClosingDateStr || estClosingDateStr
				)
			: null
	}, [estClosingDateStr, actualClosingDateStr])

	const {
		fetching,
		getMonthlyPaymentChartData,
		lastCompletedMilestone,
		activeStep,
	} = useLoanStatus({ loan })
	const chartData = getMonthlyPaymentChartData()
	const [width] = useWindowSize()

	const chartVisible = useMemo(() => {
		return (
			chartData.totalMonthlyPayment > 0 &&
			loan.Rate &&
			loan.MonthlyPaymentTaxes
		)
	}, [chartData, loan])

	if (fetching) {
		return <Loading size="small" />
	}

	return (
		<div>
			<LoanMilestones
				loan={loan}
				activeStep={activeStep}
				className="mb-3"
			/>
			<div className="lg:grid lg:grid-cols-2 lg:gap-3">
				<div className="mt-5 lg:mt-0 mb-5 lg:mb-0">
					{lastCompletedMilestone && (
						<div className="mb-3">
							<Heading size="xl">
								Loan Status: {lastCompletedMilestone.name}
							</Heading>
						</div>
					)}

					<TableContainer sx={width > 768 ? { maxWidth: 500 } : {}}>
						<Table
							sx={width > 768 ? { maxWidth: 500 } : {}}
							aria-label="simple table"
						>
							<TableBody>
								<TableRow>
									<TableCell align="left">
										<strong>
											{!actualClosingDateStr ? 'Est ' : ''}Closing
											Date
										</strong>
									</TableCell>
									<TableCell align="right">
										{actualClosingDateStr || estClosingDateStr || NA}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="left">
										<strong>Loan Purpose</strong>
									</TableCell>
									<TableCell align="right">
										{loan.LoanPurpose || NA}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="left">
										<strong>Loan Amount</strong>
									</TableCell>
									<TableCell align="right">
										{loan.LoanAmount
											? `$${applyMask(loan.LoanAmount, 'fullCurrency')}`
											: NA}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="left">
										<strong>Loan Term</strong>
									</TableCell>
									<TableCell align="right">
										{loan.LoanTerm
											? `${loan.LoanTerm / 12} years`
											: NA}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="left">
										<strong>Loan Type</strong>
									</TableCell>
									<TableCell align="right">
										{loan.CurrentLoanType || NA}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="left">
										<strong>Days to Closing</strong>
									</TableCell>
									<TableCell align="right">
										{daysToClosing || NA}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</div>
				<div>
					<Heading size="xl" className="mb-3">
						Monthly Payment Amount
					</Heading>
					<MonthlyPaymentChart
						loanId={loan.LoanID}
						data={chartData}
						visible={chartVisible}
					/>
				</div>
			</div>
		</div>
	)
}

export default LoanStatus
