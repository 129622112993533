import React, { useMemo, useState } from 'react'
import * as yup from 'yup'
import { v4 as uuidV4 } from 'uuid'
import useForm from '../../../../hooks/useForm'
import {
	Form,
	FormRow,
	TextField,
} from '../../../../components/formControls'
import { Button, LoadingBtn } from '../../../../components/Button'
import Select from '../../../../components/formControls/Select'
import { useFieldArray } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Cancel'
import BusinessRuleFilterValueSelect from './BusinessRuleFilterValueSelect'
import { clone, map } from 'lodash'
import Heading from '../../../../components/Heading'
import ErrorText from '../../../../components/ErrorText'

const BusinessRuleForm = ({
	businessRule,
	onSubmit,
	apiErrors = [],
}) => {
	const FILTER_FIELD_NAME = 'filter'
	const REQUIRED_MESSAGE = 'This field is required'
	const TYPE = 'Type'
	const schema = yup.object().shape({
		name: yup.string().required(REQUIRED_MESSAGE),
		description: yup.string().required(REQUIRED_MESSAGE),
		filter: yup
			.array()
			.of(
				yup.object().shape({
					targetFieldID: yup.string().required(REQUIRED_MESSAGE),
					targetFieldValue: yup.string().required(REQUIRED_MESSAGE),
				})
			)
			.min(1, 'At least one filter is required'),
	})

	const filterTypes = [
		{
			label: 'Loan Purpose',
			value: 'LoanPurpose',
		},
		{
			label: 'Loan Type',
			value: 'LoanType',
		},
		{
			label: 'Occupancy Type',
			value: 'OccupancyType',
		},
		{
			label: 'Property Type',
			value: 'PropertyType',
		},
		{
			label: 'Borrower Employment Type',
			value: 'BorrowerEmploymentType',
		},
		{
			label: 'Special Loan Feature',
			value: 'SpecialLoanFeature',
		},
	]

	const [allowedFilterTypes, setAllowedFilterTypes] = useState(
		clone(filterTypes)
	)

	let filter = useMemo(() => {
		if (businessRule) {
			return businessRule.filter.map((f) => {
				const normalFilterExists = !!filterTypes.find(
					(t) => t.value === f.targetFieldID
				)
				return {
					id: uuidV4(),
					advanced: !normalFilterExists,
					...f,
				}
			})
		}
		return []
	}, [businessRule])

	const values = businessRule
		? { ...businessRule, filter }
		: {
				name: businessRule?.name || '',
				description: businessRule?.description || '',
				filter,
				tasks: businessRule?.tasks || [],
				targetUserRole: businessRule?.targetUserRole || 'Borrower',
				applyToAllBorrowerPairs:
					businessRule?.applyToAllBorrowerPairs || true,
			}

	const form = useForm({
		schema,
		values,
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
	} = form

	const {
		fields: filterFields,
		append,
		remove,
	} = useFieldArray({
		control,
		name: FILTER_FIELD_NAME,
	})

	const handleAddFilter = (advanced = false) => {
		updateAvailableFilterTypes()

		append({
			id: uuidV4(),
			conditionType: 5,
			targetFieldValue: '',
			targetFieldID: '',
			advanced,
		})
	}

	const handleRemoveFilter = (index) => {
		remove(index)
		updateAvailableFilterTypes()
	}

	const updateAvailableFilterTypes = () => {
		const filterValues = form.getValues(FILTER_FIELD_NAME)
		const usedFilterTypes = map(filterValues, 'targetFieldID').filter(
			(t) => t
		)
		const newFilterTypes = clone(filterTypes)
		newFilterTypes.forEach((type, i) => {
			newFilterTypes[i].disabled = usedFilterTypes.includes(
				type.value
			)
		})
		setAllowedFilterTypes(newFilterTypes)
	}

	return (
		<Form onSubmit={handleSubmit(onSubmit)} errors={apiErrors}>
			<FormRow>
				<TextField
					name="name"
					label="Name"
					className="w-full"
					required
					control={control}
					errors={errors}
				/>
			</FormRow>
			<FormRow>
				<TextField
					name="description"
					label="Description"
					className="w-full"
					multiline
					rows={3}
					required
					control={control}
					errors={errors}
				/>
			</FormRow>
			<div className="pb-3 pt-2">
				<Heading size="md" className="mb-3">
					Filters
				</Heading>
				{filterFields.length === 0 && (
					<ErrorText>* At least 1 filter is required</ErrorText>
				)}

				{filterFields.map((filter, i) => {
					const targetFieldIdName = `${FILTER_FIELD_NAME}[${i}].targetFieldID`
					const targetFieldValueName = `${FILTER_FIELD_NAME}[${i}].targetFieldValue`
					return (
						<FormRow key={filter.id} className="mb-3 flex flex-row">
							<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
								{filter.advanced ? (
									<TextField
										name={targetFieldIdName}
										label="Field ID"
										className="w-full pr-3"
										required
										control={control}
										errors={errors}
									/>
								) : (
									<Select
										name={targetFieldIdName}
										className="w-full pr-3"
										label={TYPE}
										menuItems={allowedFilterTypes}
										control={control}
										errors={errors}
										required
									/>
								)}
							</div>
							<div className="flex flex-row w-full md:w-1/2 md:mb-0 md:max-w-screen-sm md:pl-3">
								{filter.advanced ? (
									<TextField
										name={targetFieldValueName}
										label="Value"
										className="w-full"
										required
										control={control}
										errors={errors}
									/>
								) : (
									<BusinessRuleFilterValueSelect
										name={targetFieldValueName}
										typeName={targetFieldIdName}
										className="w-full"
										required
										displayEmpty
										defaultValue={filter.targetFieldValue}
										control={control}
										errors={errors}
									/>
								)}
								<IconButton onClick={() => handleRemoveFilter(i)}>
									<CancelIcon />
								</IconButton>
							</div>
						</FormRow>
					)
				})}
				<div className="flex flex-row justify-between items-center mt-3">
					<Button
						text="Add Filter"
						onClick={() => handleAddFilter()}
					/>
					<Button
						text="Add Advanced Filter"
						onClick={() => handleAddFilter(true)}
						variant="outlined"
					/>
				</div>
			</div>

			<FormRow className="mt-3">
				<LoadingBtn
					disabled={isSubmitting}
					loading={isSubmitting}
					type="submit"
					text="Save"
				/>
			</FormRow>
		</Form>
	)
}

export default BusinessRuleForm
