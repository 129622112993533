import React from 'react'
import * as yup from 'yup'
import useForm from '../hooks/useForm'
import { Button, LoadingBtn } from '../components/Button'
import { TextField, Form, FormRow } from '../components/formControls'

export const DeleteMeForm = ({ onSubmit, onClose }) => {
	const schema = yup.object().shape({
		feedback: yup.string().required('Feedback is required'),
	})

	const form = useForm({
		schema,
		resetOptions: {
			keepValues: false,
		},
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
		control,
		getValues,
		reset,
	} = form

	const submitForm = async () => {
		await onSubmit(getValues())
		handleCancel()
	}

	const handleCancel = () => {
		onClose()
		reset({ feedback: '' })
	}

	return (
		<Form onSubmit={handleSubmit(submitForm)} className="w-full">
			<div className="flex flex-col md:flex-row">
				<div className="w-full md:mb-0 md:max-w-screen-sm">
					<FormRow className="mb-5">
						<TextField
							name="feedback"
							label="Feedback"
							className="w-full"
							placeholder="Provide the reason for requesting your account deletion"
							multiline
							rows={3}
							control={control}
							errors={errors}
						/>
					</FormRow>
					<div className="mt-5 flex justify-between space-x-4">
						<Button
							text="Cancel"
							color="error"
							onClick={handleCancel}
							className="flex-grow w-full"
						/>
						<LoadingBtn
							disabled={isSubmitting || !isValid}
							loading={isSubmitting}
							type="submit"
							text="Delete"
						/>
					</div>
				</div>
			</div>
		</Form>
	)
}
