import React from 'react'
import { FormControlLabel, Switch as MuiSwitch } from '@mui/material'
import { Controller } from 'react-hook-form'

const Switch = ({
	name,
	label,
	children,
	control,
	defaultValue,
	className,
	sx,
	...rest
}) => (
	<div className={className}>
		{control && (
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				sx={sx}
				render={({ field }) => (
					<FormControlLabel
						label={label}
						control={
							<MuiSwitch
								{...field}
								{...rest}
								checked={field.value}
								onChange={(e) => field.onChange(e.target.checked)}
							/>
						}
					/>
				)}
			/>
		)}
		{!control && (
			<FormControlLabel
				label={label}
				sx={sx}
				control={
					<MuiSwitch
						name={name}
						defaultValue={defaultValue}
						{...rest}
					/>
				}
			/>
		)}
	</div>
)

export default Switch
