import React from 'react'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { states } from '../../services/helper'

export const StateLicenses = ({
	Licenses,
	handleStateLicensesSelect,
}) => {
	const secondColumnStart = Math.ceil(states.length / 2)

	return (
		<>
			<p className="mt-5 md:mt-0 mb-2.5 text-sm font-rubik">
				State licenses *
			</p>
			<FormControlLabel
				id={`StateLicensesSelectAll`}
				label={`${Licenses.length !== states.length ? 'Select' : 'Deselect'} All`}
				control={
					<Checkbox
						id={`StateLicensesSelectAllCheckbox`}
						checked={Licenses.length === states.length}
						onChange={() =>
							handleStateLicensesSelect(
								Licenses.length !== states.length ? 'all' : 'none'
							)
						}
						inputProps={{ 'aria-label': 'Select All' }}
					/>
				}
			/>
			<div className="mt-2.5 mb-3.5 flex flex-row justify-center">
				<div className="flex flex-col w-1/2 justify-start md:pl-3.5">
					<FormGroup>
						{states.slice(0, secondColumnStart).map((item, index) => (
							<FormControlLabel
								id={`StateLicenses${item.value}`}
								key={`stateLicense-${item.value + index}`}
								label={item.label}
								control={
									<Checkbox
										id={`StateLicenses${item.value}Checkbox`}
										checked={Licenses?.includes(item.value)}
										onChange={() =>
											handleStateLicensesSelect(item.value)
										}
										inputProps={{
											'aria-label': `${item.value} Checkbox`,
										}}
									/>
								}
							/>
						))}
					</FormGroup>
				</div>
				<div className="flex flex-col w-1/2 justify-start md:pl-3.5">
					<FormGroup>
						{states.slice(secondColumnStart).map((item, index) => (
							<FormControlLabel
								id={`StateLicenses${item.value}`}
								key={`stateLicense-${item.value + index}`}
								label={item.label}
								control={
									<Checkbox
										id={`StateLicenses${item.value}`}
										checked={Licenses?.includes(item.value)}
										onChange={() =>
											handleStateLicensesSelect(item.value)
										}
										inputProps={{
											'aria-label': `${item.value} Checkbox`,
										}}
									/>
								}
							/>
						))}
					</FormGroup>
				</div>
			</div>
		</>
	)
}
