import React, { useEffect } from 'react'
import DataTable from '../DataTable'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../services/queryKeys'
import { getLoanUsers } from '../../services/client'
import { useAlert } from '../../hooks'
import { getTheme } from '../../config'
import { formatDate } from '../../services/helper'

const theme = getTheme()

const LoanUsersTable = ({ loanId }) => {
	const { theme: breakpoint } = theme.breakpoints
	const DEFAULT_SORT_COLUMN = 'createdAt'

	const { alert } = useAlert()

	const {
		isFetching,
		isRefetching,
		isError,
		data: loanUsers,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.loanUsers],
		queryFn: () => getLoanUsers(loanId),
	})

	const columns = [
		{
			name: 'Name',
			selector: (row) =>
				`${row.firstName} ${row.lastName}${!row.isUser ? ' (Pending)' : ''}`,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'Role',
			selector: (row) => row.role,
			sortable: true,
		},
		{
			name: 'Created',
			selector: (row) => formatDate(row.createdAt, false),
			sortable: true,
			sortField: 'createdAt',
			hide: breakpoint,
		},
	]

	useEffect(() => {
		if (isError) {
			alert('There was a problem loading the loan users', {
				severity: 'error',
			})
		}
	}, [isError, alert])

	return (
		<DataTable
			data={loanUsers}
			columns={columns}
			defaultSortAsc={false}
			defaultSortFieldId={DEFAULT_SORT_COLUMN}
			progressPending={isFetching && !isRefetching}
			onRefreshClick={refetch}
			keyField="id"
		/>
	)
}

export default LoanUsersTable
