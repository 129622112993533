import { useMemo } from 'react'
import { useAlertsState } from './AlertsContext'
import { useAlert } from '../../hooks'
import { animated, useTransition } from '@react-spring/web'
import { Alert } from './Alert'

const alertConfig = { tension: 125, friction: 20, precision: 0.1 }
const alertTimeout = 5000

export function Alerts() {
	const { remove } = useAlert()
	const {
		state: { alerts },
	} = useAlertsState()
	const refs = useMemo(() => new Map(), [])
	const transitions = useTransition(alerts, {
		keys: (alert) => alert.id,
		from: { opacity: 0, height: 0, life: '100%' },
		leave: [{ opacity: 0 }, { height: 0 }],
		enter: (alert) => async (next) => {
			await next({
				opacity: 1,
				height: refs.get(alert.id).offsetHeight,
			})
			await next({ life: '0%' })
		},
		onRest: (result, ctrl, alert) => {
			remove(alert.id)
		},
		config: (item, index, phase) => (key) =>
			phase === 'enter' && key === 'life'
				? { duration: alertTimeout }
				: alertConfig,
	})

	return (
		<div className="fixed top-2 right-2" style={{ zIndex: 1000 }}>
			{transitions(({ life, ...style }, alert) => {
				return (
					<animated.div style={style}>
						<Alert
							life={life}
							{...alert}
							remove={() => remove(alert.id)}
							ref={(ref) => ref && refs.set(alert.id, ref)}
						/>
					</animated.div>
				)
			})}
		</div>
	)
}
