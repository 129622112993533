import { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Page from '../../components/Page'
import HowToApplyView from './HowToApplyView'
import { Footer } from '../../components/Footer'
import { HeaderOpen } from '../../components/HeaderOpen'
import { Carousel } from '../../components/Carousel/Carousel'
import { LoanOfficerCenterView } from './LoanOfficerCenterView'
import { LoanOfficerCondensedView } from './LoanOfficerCondensedView'
import { getSurveys, getSurveysByEmail } from '../../services/client'
import { getErrorMessage } from '../../services/helper'
import {
	getAppLinks,
	navigationLinking,
} from '../../services/navigation'
import { useAppContext } from '../../components/AppContext'
import { ModalByPhone } from '../../components/modals/ModalByPhone'
import banner1 from 'assets/images/landingPages/corporate-banner.png'
import banner2 from 'assets/images/landingPages/corporate-banner2.png'
import man from 'assets/images/landingPages/man.png'
import Calendar from 'assets/icons/Calendar.svg'
import Memories from 'assets/icons/Memories.svg'
import { useAlert } from '../../hooks'
import parse from 'html-react-parser'
import { stripHtmlTags } from '../../services/utils'
import AppLinkCards from './AppLinkCards'

const ratesData = [
	{
		icon: Calendar,
		name: 'Fixed 15 year',
		id: 'Fixed15Year',
		description: 'Lorem ipsum dolor',
	},
	{
		icon: Memories,
		name: 'Conventional 30 year',
		id: 'Conventional30Year',
		description: 'Lorem ipsum dolor',
	},
]

export default function Landing() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const navigate = useNavigate()
	const { siteConfig, socialLinks, legalLinks, user } = state
	const [appLinks, setAppLinks] = useState([])
	const [loading, setLoading] = useState(false)
	const [introCondensed, setIntroCondensed] = useState(false)
	const [condensedIntro, setCondensedIntro] = useState('')
	const [slideInfo, setSlideInfo] = useState([])
	const [appLinksTitle, setAppLinksTitle] = useState('')
	const [modalByPhoneVisible, setModalByPhoneVisible] =
		useState(false)
	const [mortgageRatesSections, setMortgageRatesSections] = useState([
		{ name: `Today's rates`, id: 'TodaysRates', selected: true },
		{ name: 'Fixed', id: 'Fixed', selected: false },
		{ name: 'Conventional', id: 'Conventional', selected: false },
	])

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				let surveys = []
				if (siteConfig?.entityType === 3)
					surveys = await getSurveysByEmail(siteConfig.email)
				else if (siteConfig.entityType !== 0)
					surveys = await getSurveys()
				setSlideInfo(surveys.slice(0, 10))
			} catch (e) {
				alert(getErrorMessage(e), { severity: 'error' })
			} finally {
				setLoading(false)
			}
		})()
	}, [])

	useEffect(() => {
		if (siteConfig.entityType === 0) {
			navigate(`/${navigationLinking.SignIn}`)
		} else if (siteConfig) {
			const links = getAppLinks(siteConfig)
			setAppLinks(links)

			const introductionText =
				siteConfig?.introduction &&
				siteConfig?.introduction?.replaceAll('\n', '<br/>')
			if (siteConfig?.introduction?.length > 500) {
				setCondensedIntro(`${introductionText.slice(0, 500)}...`)
				setIntroCondensed(true)
			} else {
				setCondensedIntro(introductionText)
			}

			if (siteConfig.entityType < 4)
				setAppLinksTitle(
					links.length > 1
						? 'How would you like to apply?'
						: 'Apply Now'
				)
			else setAppLinksTitle('What would you like to do today?')
		}
	}, [siteConfig])

	const changeMortgageRatesSection = (index) => {
		let newSelectedSection = [...mortgageRatesSections]
		newSelectedSection.forEach(
			(item, i) => (item.selected = index === i)
		)
		setMortgageRatesSections(newSelectedSection)
	}

	const handleAppLink = async (app) => {
		switch (app.fieldName) {
			case 'RingCentral':
				setModalByPhoneVisible(true)
				break
			case 'ringCentral':
				setModalByPhoneVisible(true)
				break
			case 'Alexa':
				await window.open(app.link)
				break
			default:
				navigate(`/${app.link}`, { state: app } || {})
		}
	}

	const strippedIntro = useMemo(() => {
		return stripHtmlTags(siteConfig?.introduction).replace('null', '')
	}, [siteConfig])

	// Standard Condensed
	if (siteConfig?.landingPageType === 1)
		return (
			<HowToApplyView siteConfig={siteConfig} appLinks={appLinks} />
		)

	if (
		[3, 4].includes(siteConfig?.entityType) &&
		siteConfig?.landingPageType === 2
	)
		return (
			<LoanOfficerCondensedView
				user={user}
				siteConfig={siteConfig}
				appLinks={appLinks}
			/>
		)

	return (
		<Page page="app-landing" noDarkMode>
			<HeaderOpen siteConfig={siteConfig} user={user} landing />

			{[3, 4].includes(siteConfig?.entityType) ? (
				<LoanOfficerCenterView siteConfig={siteConfig} user={user} />
			) : (
				<div className="flex flex-row items-center w-full">
					<img
						className="w-full md:rounded-3xl"
						src={
							siteConfig?.bannerUrl ? siteConfig.bannerUrl : banner1
						}
						alt="First Banner"
					/>
				</div>
			)}

			<div className="flex items-center flex-col justify-center py-5">
				<p className="text-lg font-rubik font-bold sm:text-2xl text-center mt-6 pb-4">
					{appLinksTitle}
				</p>
				<div className="w-14 align-center h-0.5 bg-slate-300 sm:h-0" />
				{siteConfig?.description && (
					<div className="w-3/5 text-center mt-4 mb-10 text-sm sm:text-xl">
						{siteConfig.description}
					</div>
				)}
				<AppLinkCards
					appLinks={appLinks}
					source="LandingAppLink"
					className="mt-7 md:mt-14"
				/>
			</div>

			{strippedIntro && (
				<div className="flex flex-col items-center bg-slate-100 mt-0 mb-0 md:mt-10 md:mb-12 lg:rounded-3xl md:rounded-none dark:bg-slate-800">
					<div className="py-5 px-8 md:p-12 w-full">
						<p className="text-xl md:text-2xl text-center font-rubik font-bold mt-6 pb-10">
							{siteConfig?.introductionTitle
								? siteConfig?.introductionTitle
								: `Who is ${siteConfig?.name}?`}
						</p>
						{introCondensed &&
						siteConfig?.introduction?.length > 0 ? (
							<div className="mx-auto text-sm md:text-base font-montserrat text-justify w-full md:w-2/3">
								{parse(condensedIntro)}&nbsp;
								<Typography
									variant="link"
									onClick={() => setIntroCondensed(false)}
								>
									show full bio
								</Typography>
							</div>
						) : (
							<div className="mx-auto text-sm md:text-base font-montserrat text-justify w-full md:w-2/3">
								{parse(
									siteConfig?.introduction?.replaceAll(
										'\n',
										'<br/>'
									) || ''
								)}
							</div>
						)}
					</div>
				</div>
			)}

			{siteConfig?.secondaryBannerUrl && (
				<img
					className="md:pt-6 md:pb-12 pt-8 pb-4 md:rounded-3xl md:min-h-[auto] min-h-[200px] object-cover"
					src={siteConfig?.secondaryBannerUrl}
					alt="Second Banner"
				/>
			)}

			{siteConfig?.enabledServices.rates !== false && (
				<div className="w-full flex flex-col justify-between items-center px-3.5 my-5">
					<p className="text-xl font-rubik text-center font-bold mt-6 pb-3.5 md:text-2xl">
						Mortgage rates
					</p>
					<div className="w-14 align-center h-0.5 bg-slate-300 my-1" />

					<div className="flex flex-col justify-center items-center w-full md:flex-row md:flex-wrap">
						<div className="flex flex-row w-full justify-center mb-10 md:hidden">
							{mortgageRatesSections.map((section, index) => (
								<button
									className={
										section.selected
											? 'text-blue-500 border-blue-500 border-b-2 h-10 mx-3.5 flex justify-center items-center'
											: 'h-10 mx-3.5 flex justify-center items-center'
									}
									key={`${section.name + index}`}
									onClick={() => changeMortgageRatesSection(index)}
								>
									<p
										className={[
											'text-center',
											section.selected
												? 'text-blue-500'
												: 'text-slate-500',
										]}
									>
										{section.name}
									</p>
								</button>
							))}
						</div>
						{mortgageRatesSections.find(
							(item) => item.selected === true && item.id === 1
						) ? (
							<div className="flex flex-col items-center h-40 w-80 mx-4 mb-4 bg-blue-100 shadow shadow-gray-600/30 rounded-lg md:mx-0">
								<p className="font-bold mb-5 mt-8 w-4/5 text-center text-lg">
									Check out today's rates
								</p>
								<button className="border-2 rounded border-slate-500 w-48 py-2.5 text-sm">
									Get your rate
								</button>
							</div>
						) : mortgageRatesSections.find(
								(item) => item.selected === true && item.id === 2
						  ) ? (
							<div
								className="flex flex-col justify-center items-center h-40 w-72 mx-4 mb-4 shadow shadow-gray-600/30 rounded-lg md:hidden"
								onClick={() =>
									navigate(`/${navigationLinking.Apply}`)
								}
							>
								<img
									src={Calendar}
									alt="Calendar"
									className="h-12 w-16"
								/>
								<p className="text-lg text-center font-bold mt-2.5">
									Fixed 15 year
								</p>
								<p className="text-center mt-1">Lorem ipsum dolor</p>
							</div>
						) : (
							<div
								className="flex flex-col justify-center items-center h-40 w-72 mx-4 mb-4 shadow shadow-gray-600/30 rounded-lg md:hidden"
								onClick={() =>
									navigate(`/${navigationLinking.Apply}`)
								}
							>
								<img
									src={Memories}
									alt="Memories"
									className="h-12 w-16"
								/>
								<p className="text-lg text-center font-bold mt-2.5">
									Conventional 30 year
								</p>
								<p className="text-center mt-1">Lorem ipsum dolor</p>
							</div>
						)}
					</div>
					<div className="hidden md:mt-5 md:py-10 md:w-full md:flex md:flex-row md:flex-wrap md:justify-center">
						<div className="flex flex-col items-center h-56 w-80 mr-4 mb-4 bg-blue-100 shadow shadow-gray-600/30 rounded-lg">
							<p className="font-bold mb-5 mt-14 text-center text-2xl">
								Check out today"s rates
							</p>

							<button className="border-2 rounded border-slate-500 w-48 py-2.5 text-sm">
								Get your rate
							</button>
						</div>
						{ratesData.map((rate, index) => (
							<div
								className="flex flex-col justify-center items-center h-56 w-80 mx-4 mb-4 shadow shadow-gray-600/30 rounded-lg"
								onClick={() =>
									navigate(`/${navigationLinking.CreateAccount}`)
								}
								key={`${rate.name + index}`}
							>
								<img
									src={rate.icon}
									alt={rate.id}
									className="h-12 w-16"
								/>
								<p className="text-lg text-center font-bold mt-2.5">
									{rate.name}
								</p>
								<p className="text-center mt-1">{rate.description}</p>
							</div>
						))}
					</div>
				</div>
			)}

			{siteConfig?.enabledServices.socialSurvey &&
				slideInfo.length > 0 &&
				[3].includes(siteConfig?.entityType) && (
					<div className="w-full flex flex-col items-center justify-between mt-5 px-4 py-5 bg-slate-100 dark:bg-slate-800 lg:rounded-3xl md:rounded-none">
						<p className="text-lg font-rubik font-bold sm:text-2xl text-center mt-6 pb-4">
							Customer Testimonials
						</p>
						<div className="w-14 align-center mt-2 h-0.5 bg-slate-300 sm:h-0" />
						{!loading && (
							<Carousel data={slideInfo} staticImage={man} />
						)}
					</div>
				)}

			<ModalByPhone
				siteConfig={siteConfig}
				visible={modalByPhoneVisible}
				setVisible={setModalByPhoneVisible}
			/>

			<Footer
				siteConfig={siteConfig}
				socialLinks={socialLinks}
				legalLinks={legalLinks}
			/>
		</Page>
	)
}
