export const appActions = {
	setSiteConfig: 'setSiteConfig',
	setAuthToken: 'setAuthToken',
	setUser: 'setUser',
	setInvite: 'setInvite',
	logOut: 'logOut',
	setRedirectUrl: 'setRedirectUrl',
	setImpersonationRequest: 'setImpersonationRequest',
}

export const reducer = (state = {}, action) => {
	switch (action.type) {
		case appActions.setSiteConfig:
			return {
				...state,
				siteConfig: action.payload.siteConfig,
				socialLinks: action.payload.socialLinks,
				legalLinks: action.payload.legalLinks,
			}
		case appActions.setAuthToken:
			return {
				...state,
				authToken: action.payload,
			}
		case appActions.setUser:
			return {
				...state,
				user: action.payload,
			}
		case appActions.setInvite:
			return {
				...state,
				invite: action.payload,
			}
		case appActions.setRedirectUrl:
			return {
				...state,
				invite: action.payload,
			}
		case appActions.setImpersonationRequest:
			return {
				...state,
				impersonationRequest: action.payload,
			}
		case appActions.logOut:
			return {
				...state,
				user: null,
				authToken: null,
				invite: null,
				impersonationRequest: null,
			}
		default:
			console.info({ action })
			throw new Error('Unknown Action')
	}
}
